

body::after {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3; // Must be lower than from ".search-open" and ".ocopen" to prevent flash on removing "dialog-open"
    background: fade(@white,50);
    opacity: 0;
    transition: opacity .35s ease;
    pointer-events: none;

    .search-open &,
    .ocopen & {
        opacity: 1;
        z-index: 4;
    }

    .dialog-open & {
        opacity: 1;
    }
}