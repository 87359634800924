.socialmedia-wall {
    text-align: center;
}

.socialmedia-wall__disclaimer {
    text-align: center;

    p {
        margin-bottom: 8px;
    }
}
