
.js-playlist {
    position: relative;
    margin-bottom: 50px;
    padding-bottom: 50px;
}

.js-playlist__screen {
    display: flex;
}

.js-playlist__more-btn {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
}

.js-playlist__add-btn {
    max-width: 40px;
    opacity: 1;
    white-space: nowrap;
    overflow: hidden;
    transition: all .5s ease;

    .obb-icon-plus {
        width: 30px;
        margin-right: 5px;
        font-size: 18px;
    }

    &:hover,
    &:focus {
        max-width: 400px;
    }

    .js-playlist__item--added & {
        opacity: 0;
    }
}

.custom-playlist {
    position: relative;
    width: 33.3333%;
    margin: 0 0 30px 20px;
    background: white;
    overflow-y: auto;
}

.custom-playlist__headline {
    position: sticky;
    top: 0;
    padding-top: 10px;
    background: white;
}

.custom-playlist__items {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 10px 10px;
}

.custom-playlist__item {
    position: relative;
    padding-right: 30px;
}

.custom-playlist__content {
    display: flex;
    align-items: flex-start;
    margin: 0 0 20px;
    color: @black-100;

    &:last-child {
        margin-bottom: 0;
    }
}

.custom-playlist__content-thumb {
    width: 35%;
    margin-right: 5px;
}

.custom-playlist__content-title {
    font-family: @font-bold;
    font-size: 14px;
    line-height: 1.2em;
}

.custom-playlist__content-channel {
    font-size: 14px;
    line-height: 1.2em;
    font-family: @font-light;
}

.custom-playlist__remove-btn {
    position: absolute;
    right: 0;
    top: 0;
}