
.article__content--break-out {
    margin-left: -15px;
    margin-right: -15px;
    padding-right: 15px;
    padding-left: 15px;

    @media @screen-xs-min {
        margin-left: ~'calc(((((100vw - 560px) / 2) + ((560px - 560px / 12 * 10) / 2 + 15px)) *-1))';
        margin-right: ~'calc(((((100vw - 560px) / 2) + ((560px - 560px / 12 * 10) / 2 + 15px)) *-1))';
    }

    @media @screen-md-min {
        margin-left: ~'calc((100vw - (100vw - 30px) / 12 * 10) * -1)';
        margin-right: ~'calc((100vw - (100vw - 30px) / 12 * 10) * -1)';
    }

    @media @screen-lg-min {
        margin-left: ~'calc((((100vw - 1140px) / 2) + ((1140px - 1140px / 12 * 10) / 2 + 90px + 15px)) *-1)';
        margin-right: ~'calc((((100vw - 1140px) / 2) + ((1140px - 1140px / 12 * 10) / 2 + 90px + 15px)) *-1)';
    }
}

.content--expand {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: -15px;
    padding-right: -15px;

    @media @screen-lg-min {
        margin-left: ~'calc((100vw - 1140px) / 2 * -1 - 15px)';
        margin-right: ~'calc((100vw - 1140px) / 2 * -1 - 15px)';
    }
}