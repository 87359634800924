.media {
    display: flex;

    &__meta {
        margin-right: 20px;
    }

    &__body {
        flex: 1 1 0%;
    }
}
