
.jobprofile {
    margin-bottom: 30px;
    background: white;
}

.jobprofile__image-container {
    text-align: center;
    height: 100%;
}

.jobprofile__image-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    
    &:before,
    &:after {
        content: "";
        display: block;
        width: 35%;
        max-width: 150px;
        height: 100%;
        position: absolute;
        top: 0;
        transform-origin: bottom;
        background: white;
    }

    &:before {
        transform: translateX(-100%) skew(-30deg);
        left: 0;
    }

    &:after {
        transform: skew(-30deg);
        right: 0;
    }
}

.jobprofile__image {
    display: block;
    min-height: 100%;
}

.jobprofile__content {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: @font-light;
    padding: 15px;
}

.jobprofile__text {
    font-size: 24px;
    margin-bottom: 10px;

    @media @screen-md-min {
        font-size: 32px;
    }
}

.jobprofile__subtext {
    font-size: 14px;

    @media @screen-md-min {
        font-size: 18px;
    }
}