// Loops for spaces and padding
.for(@list, @code) {
    & {
        .loop(@i:1) when (@i =< length(@list)) {
            @value: extract(@list, @i);
            @code();
            .loop(@i + 1);
        }
        .loop();
    }
}

// Loop for responsive breakpoints
.make-responsive-columns(@counter, @media) when (@counter > 0) {
	.col-@{media}-@{counter} {
      flex: 0 0 percentage(@counter / @grid-columns);
      max-width: percentage(@counter / @grid-columns);
	}

  .col-offset-@{media}-@{counter} {
    margin-left: percentage(@counter / @grid-columns);
	}
  .make-responsive-columns((@counter - 1), @media);
}

// Loop for normal cols
.make-columns(@counter) when (@counter > 0) {
  .col-@{counter} {
    flex: 0 0 percentage(@counter / @grid-columns);
    max-width: percentage(@counter / @grid-columns);
  }

  .col-offset-@{counter} {
    margin-left: percentage(@counter / @grid-columns);
  }
  .make-columns(@counter - 1);
}


@media @screen-md-min {
  .col-md-5th {
    flex: 0 0 20%;
    max-width: 20%;
  }
}