.dialogShadow {
  box-shadow: 0 35px 35px -35px rgba(0, 0, 0, 0.19);
}
@media screen and (min-width: 920px) {
  .col-md-5th {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}
[class*='col-'] {
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 100%;
}
.row--white {
  background: white;
  margin-left: 0;
  margin-right: 0;
  padding-top: 15px;
  padding-bottom: 15px;
}
.row--white.no-gutters {
  padding-right: 15px;
  padding-left: 15px;
}
.vertical-align--top {
  align-items: flex-start;
}
.vertical-align--center {
  align-items: center;
}
.vertical-align--bottom {
  align-items: flex-end;
}
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.col-offset-12 {
  margin-left: 100%;
}
.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}
.col-offset-11 {
  margin-left: 91.66666667%;
}
.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}
.col-offset-10 {
  margin-left: 83.33333333%;
}
.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.col-offset-9 {
  margin-left: 75%;
}
.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}
.col-offset-8 {
  margin-left: 66.66666667%;
}
.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}
.col-offset-7 {
  margin-left: 58.33333333%;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-offset-6 {
  margin-left: 50%;
}
.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}
.col-offset-5 {
  margin-left: 41.66666667%;
}
.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}
.col-offset-4 {
  margin-left: 33.33333333%;
}
.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col-offset-3 {
  margin-left: 25%;
}
.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}
.col-offset-2 {
  margin-left: 16.66666667%;
}
.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}
.col-offset-1 {
  margin-left: 8.33333333%;
}
@media screen and (min-width: 576px) {
  .col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-offset-xs-12 {
    margin-left: 100%;
  }
  .col-xs-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-offset-xs-11 {
    margin-left: 91.66666667%;
  }
  .col-xs-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-offset-xs-10 {
    margin-left: 83.33333333%;
  }
  .col-xs-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-offset-xs-9 {
    margin-left: 75%;
  }
  .col-xs-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-offset-xs-8 {
    margin-left: 66.66666667%;
  }
  .col-xs-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-offset-xs-7 {
    margin-left: 58.33333333%;
  }
  .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-offset-xs-6 {
    margin-left: 50%;
  }
  .col-xs-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-offset-xs-5 {
    margin-left: 41.66666667%;
  }
  .col-xs-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-offset-xs-4 {
    margin-left: 33.33333333%;
  }
  .col-xs-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-offset-xs-3 {
    margin-left: 25%;
  }
  .col-xs-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-offset-xs-2 {
    margin-left: 16.66666667%;
  }
  .col-xs-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-offset-xs-1 {
    margin-left: 8.33333333%;
  }
}
@media screen and (min-width: 768px) {
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-offset-sm-12 {
    margin-left: 100%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-offset-sm-9 {
    margin-left: 75%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-offset-sm-6 {
    margin-left: 50%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-offset-sm-3 {
    margin-left: 25%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-offset-sm-1 {
    margin-left: 8.33333333%;
  }
}
@media screen and (min-width: 920px) {
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-offset-md-12 {
    margin-left: 100%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-offset-md-11 {
    margin-left: 91.66666667%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-offset-md-10 {
    margin-left: 83.33333333%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-offset-md-9 {
    margin-left: 75%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-offset-md-8 {
    margin-left: 66.66666667%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-offset-md-7 {
    margin-left: 58.33333333%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-offset-md-6 {
    margin-left: 50%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-offset-md-5 {
    margin-left: 41.66666667%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-offset-md-4 {
    margin-left: 33.33333333%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-offset-md-3 {
    margin-left: 25%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-offset-md-2 {
    margin-left: 16.66666667%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-offset-md-1 {
    margin-left: 8.33333333%;
  }
}
@media screen and (min-width: 1280px) {
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-offset-lg-12 {
    margin-left: 100%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-offset-lg-9 {
    margin-left: 75%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-offset-lg-6 {
    margin-left: 50%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-offset-lg-3 {
    margin-left: 25%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-offset-lg-1 {
    margin-left: 8.33333333%;
  }
}
@media screen and (min-width: 1440px) {
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-offset-xl-12 {
    margin-left: 100%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-offset-xl-9 {
    margin-left: 75%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-offset-xl-6 {
    margin-left: 50%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-offset-xl-3 {
    margin-left: 25%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-offset-xl-1 {
    margin-left: 8.33333333%;
  }
}
.media {
  display: flex;
}
.media__meta {
  margin-right: 20px;
}
.media__body {
  flex: 1 1 0%;
}
.button {
  display: inline-block;
  font-family: 'FrutigerNextBold', Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  padding: 16px 20px;
  border: none;
  border-radius: 0;
  cursor: pointer;
}
.button:hover,
.button:focus {
  text-decoration: none;
}
.button--primary {
  background: #E2002A;
  color: #fff;
}
.button--primary:hover,
.button--primary:focus,
.button--primary:active {
  background: #AB0020;
}
.button--primary-disabled,
.button--primary:disabled,
.button--primary[disabled] {
  background: #646463;
  color: #fff;
  pointer-events: none;
}
.button--secondary {
  border: 1px solid #E2002A;
  color: #E2002A;
  background: #fff;
}
.button--secondary:hover,
.button--secondary:focus,
.button--secondary:active {
  background: #E2002A;
  color: #fff;
}
.button:disabled,
.button--secondary-disabled,
.button--secondary[disabled] {
  border: 1px solid #646463;
  color: #646463;
  pointer-events: none;
  background: #fff;
}
.button--inverted {
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
}
.button--inverted:hover,
.button--inverted:focus,
.button--inverted:active {
  background: #fff;
  color: #E2002A;
}
.button:disabled,
.button--inverted-disabled,
.button--inverted[disabled] {
  border: 1px solid #EFEFEF;
  background: transparent;
  color: #EFEFEF;
}
.button--monochrom {
  background: #fff;
  color: #000000;
}
.button:disabled,
.button--monochrom-disabled,
.button--monochrom[disabled] {
  background: #ABABAB;
  color: #fff;
}
.button [class*="obb-icon-"] {
  width: 1em;
  height: 1em;
  font-size: 18px;
}
.button--link {
  padding: 0;
  border: none;
  background: transparent;
  color: #E2002A;
}
.button--link:hover,
.button--link:focus,
.button--link:active {
  color: #E2002A;
  text-decoration: underline;
}
.oebb-dialog .button--link {
  padding: 0 20px 20px 0;
}
.button--align-right {
  display: block;
  margin-left: auto;
}
form,
.form {
  margin: 0;
  padding: 0;
}
form p,
.form p,
form fieldset,
.form fieldset,
form .control,
.form .control {
  margin: 0 0 1em;
  padding: 0;
}
form fieldset,
.form fieldset {
  border: none;
}
form--disabled,
.form--disabled {
  position: relative;
}
form--disabled::before,
.form--disabled::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.15);
}
legend,
label {
  display: block;
  padding: 6px 0;
  color: #383736;
}
input,
textarea {
  outline: none;
}
input::-webkit-input-placeholder {
  line-height: normal !important;
}
textarea {
  resize: vertical;
}
.control-text,
input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="date"],
input[type="file"],
textarea {
  display: inline-block;
  padding: 15px 15px;
  background: #fff;
  border: none;
  border-radius: 0;
  box-shadow: inset 0 -1px 0 #959595;
}
.control-text:focus,
input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="date"]:focus,
input[type="file"]:focus,
textarea:focus {
  box-shadow: inset 0 -2px 0 #E2002A;
}
.control-text:disabled,
input[type="text"]:disabled,
input[type="email"]:disabled,
input[type="password"]:disabled,
input[type="search"]:disabled,
input[type="date"]:disabled,
input[type="file"]:disabled,
textarea:disabled,
.control-text[disabled],
input[type="text"][disabled],
input[type="email"][disabled],
input[type="password"][disabled],
input[type="search"][disabled],
input[type="date"][disabled],
input[type="file"][disabled],
textarea[disabled] {
  background-color: #e6e6e6;
}
.control-has-error .control-text,
.control-has-error input[type="text"],
.control-has-error input[type="email"],
.control-has-error input[type="password"],
.control-has-error input[type="search"],
.control-has-error input[type="date"],
.control-has-error input[type="file"],
.control-has-error textarea {
  border: 1px solid #DC3545;
}
.control-full-width .control-text,
.control-full-width input[type="text"],
.control-full-width input[type="email"],
.control-full-width input[type="password"],
.control-full-width input[type="search"],
.control-full-width input[type="date"],
.control-full-width input[type="file"],
.control-full-width textarea,
.form--controls-full-width .control-text,
.form--controls-full-width input[type="text"],
.form--controls-full-width input[type="email"],
.form--controls-full-width input[type="password"],
.form--controls-full-width input[type="search"],
.form--controls-full-width input[type="date"],
.form--controls-full-width input[type="file"],
.form--controls-full-width textarea {
  width: 100%;
}
.control-text.gray,
input[type="text"].gray,
input[type="email"].gray,
input[type="password"].gray,
input[type="search"].gray,
input[type="date"].gray,
input[type="file"].gray,
textarea.gray {
  background-color: #EFEFEF;
}
input[type="radio"],
input[type="checkbox"] {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  margin-bottom: 0;
  opacity: 0;
}
input[type="radio"] + label,
input[type="checkbox"] + label {
  position: relative;
  display: inline-block;
  margin-left: 35px;
}
input[type="radio"] + label::before,
input[type="checkbox"] + label::before {
  position: absolute;
  top: 10px;
  left: -35px;
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #fff;
  border: 0.35em solid #fff;
  box-shadow: 0 0 0 1px #959595;
}
input[type="radio"]:focus + label::before,
input[type="checkbox"]:focus + label::before {
  background: #ABABAB;
}
input[type="radio"]:disabled + label::before,
input[type="checkbox"]:disabled + label::before,
input[type="radio"][disabled] + label::before,
input[type="checkbox"][disabled] + label::before {
  background: #e6e6e6;
  border-color: #e6e6e6;
}
input[type="radio"] + label::before {
  border-radius: 50%;
}
input[type="radio"] + label:hover::before {
  background: #ABABAB;
}
input[type="radio"]:checked + label::before {
  background: #383736;
}
input[type="checkbox"] + label:hover::before {
  content: '\e629';
  color: #ABABAB;
  line-height: 8px;
  text-indent: -5px;
  font-family: 'obb-icon' !important;
}
input[type="checkbox"]:checked + label::before {
  content: '\e629';
  line-height: 8px;
  text-indent: -5px;
  font-family: 'obb-icon' !important;
}
input[type="checkbox"]:focus + label::before {
  background: #fff;
}
input[type="checkbox"]:checked + label:hover::before {
  content: '\e629';
  color: #383736;
}
input[type="submit"] {
  display: inline-block;
  font-family: 'FrutigerNextBold', Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  padding: 16px 20px;
  border: none;
  border-radius: 0;
  cursor: pointer;
  background: #E2002A;
  color: #fff;
}
input[type="submit"]:hover,
input[type="submit"]:focus {
  text-decoration: none;
}
input[type="submit"]--secondary {
  border: 1px solid #E2002A;
  color: #E2002A;
  background: #fff;
}
input[type="submit"]--secondary:hover,
input[type="submit"]--secondary:focus,
input[type="submit"]--secondary:active {
  background: #E2002A;
  color: #fff;
}
input[type="submit"]:disabled,
input[type="submit"]--secondary-disabled,
input[type="submit"]--secondary[disabled] {
  border: 1px solid #646463;
  color: #646463;
  pointer-events: none;
  background: #fff;
}
input[type="submit"]--inverted {
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
}
input[type="submit"]--inverted:hover,
input[type="submit"]--inverted:focus,
input[type="submit"]--inverted:active {
  background: #fff;
  color: #E2002A;
}
input[type="submit"]:disabled,
input[type="submit"]--inverted-disabled,
input[type="submit"]--inverted[disabled] {
  border: 1px solid #EFEFEF;
  background: transparent;
  color: #EFEFEF;
}
input[type="submit"]--monochrom {
  background: #fff;
  color: #000000;
}
input[type="submit"]:disabled,
input[type="submit"]--monochrom-disabled,
input[type="submit"]--monochrom[disabled] {
  background: #ABABAB;
  color: #fff;
}
input[type="submit"] [class*="obb-icon-"] {
  width: 1em;
  height: 1em;
  font-size: 18px;
}
input[type="submit"]--link {
  padding: 0;
  border: none;
  background: transparent;
  color: #E2002A;
}
input[type="submit"]--link:hover,
input[type="submit"]--link:focus,
input[type="submit"]--link:active {
  color: #E2002A;
  text-decoration: underline;
}
.oebb-dialog input[type="submit"]--link {
  padding: 0 20px 20px 0;
}
input[type="submit"]--align-right {
  display: block;
  margin-left: auto;
}
input[type="submit"]:hover,
input[type="submit"]:focus,
input[type="submit"]:active {
  background: #AB0020;
}
input[type="submit"]-disabled,
input[type="submit"]:disabled,
input[type="submit"][disabled] {
  background: #646463;
  color: #fff;
  pointer-events: none;
}
input[type="submit"]:disabled {
  border: 1px solid #646463;
  color: #646463;
  pointer-events: none;
  background: #fff;
}
input[type="file"] {
  cursor: pointer;
}
.control-group {
  display: flex;
}
.control-assistive {
  display: block;
  margin-top: -10px;
  margin-bottom: 6px;
  color: #646463;
  font-size: 0.85em;
}
.control-assistive--success {
  color: #186329;
}
.control-assistive--error {
  color: #DC3545;
  font-weight: bold;
}
.article__content--break-out {
  margin-left: -15px;
  margin-right: -15px;
  padding-right: 15px;
  padding-left: 15px;
}
@media screen and (min-width: 576px) {
  .article__content--break-out {
    margin-left: calc(((((100vw - 560px) / 2) + ((560px - 560px / 12 * 10) / 2 + 15px)) *-1));
    margin-right: calc(((((100vw - 560px) / 2) + ((560px - 560px / 12 * 10) / 2 + 15px)) *-1));
  }
}
@media screen and (min-width: 920px) {
  .article__content--break-out {
    margin-left: calc((100vw - (100vw - 30px) / 12 * 10) * -1);
    margin-right: calc((100vw - (100vw - 30px) / 12 * 10) * -1);
  }
}
@media screen and (min-width: 1280px) {
  .article__content--break-out {
    margin-left: calc((((100vw - 1140px) / 2) + ((1140px - 1140px / 12 * 10) / 2 + 90px + 15px)) *-1);
    margin-right: calc((((100vw - 1140px) / 2) + ((1140px - 1140px / 12 * 10) / 2 + 90px + 15px)) *-1);
  }
}
.content--expand {
  margin-left: -15px;
  margin-right: -15px;
  padding-left: -15px;
  padding-right: -15px;
}
@media screen and (min-width: 1280px) {
  .content--expand {
    margin-left: calc((100vw - 1140px) / 2 * -1 - 15px);
    margin-right: calc((100vw - 1140px) / 2 * -1 - 15px);
  }
}
.header {
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.18);
  position: relative;
  z-index: 10;
}
.blog .header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  transition: transform 0.75s ease;
}
.blog .scroll--down .header {
  transform: translateY(-100%);
}
@media screen and (min-width: 920px) {
  .blog .scroll--down .header {
    transform: translateY(-100%) translateY(55px);
  }
}
.header--independent {
  padding: 12px 0 12px;
}
@media screen and (min-width: 920px) {
  .header--independent {
    padding: 38px 0 55px;
  }
}
.logo {
  display: inline-block;
  vertical-align: middle;
}
.logo:hover {
  text-decoration: none;
}
@media screen and (min-width: 920px) {
  .logo-wrapper {
    flex-basis: 100%;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .header--nav-inline .logo-wrapper {
    flex-basis: auto;
    margin-right: 60px;
  }
}
.logo__item {
  width: 120px;
  height: auto;
  display: inline-block;
  vertical-align: middle;
}
.logo-wrapper--custom .logo__item {
  width: 65px;
}
@media screen and (min-width: 920px) {
  .logo__item {
    width: 200px;
  }
  .logo-wrapper--custom .logo__item {
    width: 100px;
  }
}
@media screen and (min-width: 1280px) {
  .logo__item {
    width: 265px;
  }
}
.logo__title {
  display: inline-block;
  vertical-align: middle;
  color: #383736;
  font-family: 'FrutigerNextLight', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-style: normal;
}
@media screen and (min-width: 576px) {
  .logo__title {
    font-size: 24px;
  }
}
@media screen and (min-width: 920px) {
  .logo__title {
    font-size: 36px;
  }
}
.header--nav-inline .logo__title {
  vertical-align: bottom;
}
.logo .mgnlEditorBar {
  display: block;
}
.logo__item + .logo__title {
  margin-left: 10px;
}
@media screen and (min-width: 920px) {
  .logo__item + .logo__title {
    margin-left: 20px;
  }
}
.service-nav {
  display: none;
}
@media screen and (min-width: 920px) {
  .service-nav {
    display: block;
    margin-bottom: -10px;
    list-style: none;
    text-align: right;
  }
  .service-nav__list-item {
    display: inline-block;
    margin-left: 20px;
    font-family: 'FrutigerNextMedium', Arial, Helvetica, sans-serif;
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 18px;
    position: relative;
    z-index: 1;
  }
  .service-nav__list-item--newsletter {
    width: 185px;
  }
  .service-nav__list-item--linkedin {
    width: 115px;
  }
  .service-nav__list-item--rcg {
    width: 90px;
  }
  .service-nav__link {
    color: #646463;
    padding: 13px 0 7px;
    display: block;
  }
}
.newsletter-servicelink {
  background: #E2002A;
  padding-left: 10px;
  padding-right: 10px;
  color: #fff;
  font-family: 'FrutigerNextBold', Arial, Helvetica, sans-serif;
  transform: skew(-22deg);
  transform-origin: bottom;
  text-align: center;
}
.newsletter-servicelink span {
  transform: skew(22deg);
  display: block;
}
.newsletter-servicelink:hover {
  background: #AB0020;
  text-decoration: none;
}
.search-toggler {
  display: block;
  width: 26px;
  height: 50px;
  text-align: center;
  position: relative;
  right: 0;
  margin-left: auto;
  padding: 12px 0;
  color: #383736;
}
.search-toggler:focus,
.search-toggler:hover,
.search-toggler:active {
  color: #E2002A;
}
.search-toggler .obb-icon-search {
  font-size: 22px;
}
.search-toggler:after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #E2002A;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: width 0.35s ease;
}
.search-toggler--active {
  color: #E2002A;
}
.search-toggler--active:after {
  width: 100%;
  color: #E2002A;
}
@media screen and (min-width: 920px) {
  .search-toggler {
    position: absolute;
    height: auto;
    padding: 16px 0;
    bottom: 0;
    line-height: 1em;
  }
  .header--nav-inline .search-toggler {
    padding: 10px 0;
  }
}
.burger-link {
  order: -1;
  margin-right: 15px;
}
@media screen and (min-width: 920px) {
  .burger-link {
    display: none;
  }
}
@media screen and (max-width: 919px) {
  .main-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 275px;
    height: 100%;
    transform: translateX(-100%);
    background: linear-gradient(180deg, #E2002A 60px, #F5F2EF 60px);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.18);
    z-index: 5;
    transition: transform 0.35s ease;
  }
  .ocopen .main-menu {
    transform: translateX(0%);
  }
}
@media screen and (min-width: 576px) {
  .main-menu {
    width: 320px;
  }
}
@media screen and (min-width: 920px) {
  .main-menu {
    width: auto;
  }
}
.main-nav {
  list-style: none;
}
@media screen and (max-width: 919px) {
  .main-nav {
    margin-top: 50px;
  }
}
@media screen and (min-width: 920px) {
  .main-nav {
    display: flex;
    align-items: center;
    min-height: 55px;
    margin-top: 0;
  }
  .header--nav-inline .main-nav {
    min-height: 45px;
  }
}
.main-nav__list-item {
  position: relative;
}
@media screen and (min-width: 920px) {
  .main-nav__list-item {
    margin-right: 25px;
  }
  .main-nav__list-item--has-sublevel {
    margin-right: 15px;
  }
  .main-nav__list-item--home {
    display: none;
  }
}
@media screen and (min-width: 1280px) {
  .main-nav__list-item {
    margin-right: 35px;
  }
  .main-nav__list-item--has-sublevel {
    margin-right: 25px;
  }
}
.main-nav__list-item--current-active > a {
  text-decoration: underline;
}
.main-nav__list-item--current-open > a {
  text-decoration: underline;
}
.main-item {
  display: inline-block;
  padding: 8px 0;
  font-size: 18px;
  font-family: 'FrutigerNextLight', Arial, Helvetica, sans-serif;
  color: #000000;
}
@media screen and (max-width: 919px) {
  .main-item {
    line-height: 50px;
    text-transform: uppercase;
    padding: 0 12px;
    border-bottom: 1px solid #D6D6D6;
    background: #fff;
    display: block;
  }
}
@media screen and (min-width: 920px) {
  .main-item {
    line-height: 1em;
    font-size: 18px;
  }
}
@media screen and (min-width: 1280px) {
  .main-item {
    font-size: 18px;
  }
}
.main-item__toggler {
  display: inline-block;
  background: none;
  border: none;
  border-left: 1px solid #D6D6D6;
  transform: translateX(-5px);
  cursor: pointer;
  color: #000000;
  text-align: center;
}
@media screen and (max-width: 919px) {
  .main-item__toggler {
    position: absolute;
    top: 1px;
    right: 0;
    display: block;
    width: 50px;
    height: 48px;
    line-height: 48px;
  }
}
@media screen and (min-width: 920px) {
  .main-item__toggler {
    margin-left: 8px;
  }
}
.main-item__toggler .obb-icon-arrow-down {
  display: inline-block;
}
.main-item__toggler .obb-icon-arrow-up {
  display: none;
}
.main-nav__list-item--open .main-item__toggler .obb-icon-arrow-down,
.main-nav__list-item--open-keep .main-item__toggler .obb-icon-arrow-down {
  display: none;
}
.main-nav__list-item--open .main-item__toggler .obb-icon-arrow-up,
.main-nav__list-item--open-keep .main-item__toggler .obb-icon-arrow-up {
  display: inline-block;
}
@media screen and (max-width: 919px) {
  .main-nav__list-item--current-open .main-item__toggler .obb-icon-arrow-down {
    display: none;
  }
  .main-nav__list-item--current-open .main-item__toggler .obb-icon-arrow-up {
    display: inline-block;
  }
}
.main-nav__close {
  display: none;
}
@media screen and (max-width: 919px) {
  .main-nav__close {
    display: block;
    position: absolute;
    right: 0;
    width: 50px;
    height: 50px;
    z-index: 5;
    min-height: 50px;
    text-align: center;
    line-height: 48px;
    color: #fff;
  }
  .main-nav__close i {
    vertical-align: middle;
  }
}
.main-nav__sublevel {
  display: none;
}
@media screen and (max-width: 919px) {
  .main-nav__sublevel {
    display: none;
    background: #D6D6D6;
    font-size: 16px;
    line-height: 2em;
  }
}
.main-nav__sublevel ul {
  list-style: none;
}
@media screen and (max-width: 919px) {
  .main-nav__list-item--current-open .main-nav__sublevel,
  .main-nav__list-item--open .main-nav__sublevel,
  .main-nav__list-item--open-keep .main-nav__sublevel {
    display: block;
    background-color: #f3f3f3;
  }
}
@media screen and (min-width: 920px) {
  .main-nav__list-item--open .main-nav__sublevel,
  .main-nav__list-item--open-keep .main-nav__sublevel,
  .no-js .main-nav__list-item > a:hover ~ .main-nav__sublevel,
  .no-js .main-nav__sublevel:hover {
    position: absolute;
    left: 0;
    display: block;
    min-width: 150px;
    background-color: #fff;
    border: 1px solid #D6D6D6;
    border-radius: 5px;
    transform: translate(-2px, 0);
  }
}
.sublevel-item {
  position: relative;
  display: block;
  border-bottom: 1px solid #D6D6D6;
  color: #000000;
  text-decoration: none;
}
@media screen and (max-width: 919px) {
  .sublevel-item {
    padding: 4px 50px 4px 12px;
  }
}
@media screen and (min-width: 920px) {
  .sublevel-item {
    padding: 10px 14px;
    border-color: #D6D6D6;
  }
}
.sitemap {
  padding-left: 20px;
}
.sitemap__sublevel {
  padding-left: 20px;
}
.lang-nav {
  list-style: none;
  position: absolute;
  bottom: 14px;
}
@media screen and (max-width: 919px) {
  .lang-nav {
    top: 12px;
    bottom: auto;
    left: 12px;
    display: flex;
    transform: translateX(-110%);
    transition: transform 0.35s ease;
    align-items: center;
  }
  .ocopen .lang-nav {
    transform: translateX(0%);
  }
  .ocopen .lang-nav--slide-out {
    transform: translateX(-110%);
  }
  .lang-nav__item {
    margin-right: 10px;
    padding: 5px;
    line-height: 1em;
  }
  .lang-nav__item--active {
    background: #fff;
  }
  .lang-nav__item--active .lang-nav__link {
    color: #000000;
  }
}
@media screen and (min-width: 920px) {
  .lang-nav {
    position: absolute;
    right: 30px;
    background: #fff;
    padding: 0;
    display: flex;
    bottom: 16px;
  }
  .header--nav-inline .lang-nav {
    bottom: 10px;
  }
  .lang-nav--no-right {
    right: 0;
  }
}
@media screen and (min-width: 1280px) {
  .lang-nav {
    right: 40px;
  }
  .lang-nav--no-right {
    right: 0;
  }
}
@media screen and (min-width: 920px) {
  .lang-nav__item {
    margin-left: 5px;
    padding: 0 5px;
  }
  .lang-nav__item--active {
    background: #646463;
  }
  .lang-nav__item--active .lang-nav__link {
    color: #fff;
  }
}
.lang-nav__link {
  color: #fff;
  font-size: 18px;
  line-height: 1em;
  font-family: 'FrutigerNextLight', Arial, Helvetica, sans-serif;
}
@media screen and (min-width: 920px) {
  .lang-nav__link {
    color: #383736;
    font-size: 16px;
  }
  .lang-nav__link:hover,
  .lang-nav__link:focus {
    text-decoration: none;
    color: #E2002A;
  }
}
@media screen and (min-width: 1280px) {
  .lang-nav__link {
    font-size: 18px;
  }
}
.lang-nav .lang-nav__icon {
  display: none;
}
@media screen and (min-width: 920px) {
  .lang-nav .lang-nav__icon {
    display: inline-block;
  }
}
.search-box {
  width: 100%;
  background: #fff;
  padding: 20px 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.35s ease;
  z-index: 5;
}
.search-open .search-box {
  opacity: 1;
  pointer-events: auto;
  box-shadow: 0 35px 35px -35px rgba(0, 0, 0, 0.19);
}
.search-box--attached {
  position: static;
  left: auto;
  opacity: 1;
  transform: none;
  pointer-events: auto;
}
@media screen and (min-width: 920px) {
  .search-box {
    font-size: 28.6px;
    padding: 35px 0;
  }
}
.search-box__title {
  color: #646463;
  font-family: 'FrutigerNextLight', Arial, Helvetica, sans-serif;
  font-size: 18.6px;
  line-height: 1em;
  margin-bottom: 12px;
}
@media screen and (min-width: 920px) {
  .search-box__title {
    font-size: 28.6px;
  }
}
.search-form__control {
  position: relative;
}
@media screen and (min-width: 768px) {
  .search-form__control {
    display: flex;
    align-items: flex-start;
  }
}
.search-form__input {
  height: 50px;
  border: none;
  -webkit-appearance: none;
  background-color: #EFEFEF;
  padding: 18px 15px 15px;
  font-size: 18px;
  line-height: 1em;
  font-family: 'FrutigerNextRegular', Arial, Helvetica, sans-serif;
  border-left: 1px solid #EFEFEF !important;
}
.search-form__input:focus {
  outline: none;
}
.search-form__submit {
  -webkit-appearance: none;
  border: none;
  color: #fff;
  font-family: 'FrutigerNextBold', Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 1em;
  text-align: center;
  padding: 16px;
  flex-basis: 15%;
  background: #E2002A;
  position: absolute;
  top: 0;
  right: 0;
}
.search-form__submit:hover,
.search-form__submit:focus {
  background: #AB0020;
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .search-form__submit {
    position: static;
  }
}
.sortbutton-wrapper {
  display: none;
  justify-content: flex-end;
  padding-top: 20px;
}
.sortbutton {
  margin-left: 15px;
  padding: 2px 4px;
  color: #383736;
  text-transform: uppercase;
}
.sortbutton--active {
  color: #fff;
  background-color: #646463;
}
.filter {
  position: relative;
  min-height: 50px;
  z-index: 4;
  background: #fff;
  text-align: center;
}
.filter__toggle {
  height: 50px;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #E2002A;
  font-family: 'FrutigerNextBold', Arial, Helvetica, sans-serif;
}
.filter__toggle [class="obb-icon-filter"] {
  font-size: 28px;
}
.filter__no-result {
  margin: 3em auto 2em;
  text-align: center;
}
.filter--open {
  height: auto;
  transition: height 0.35s ease;
}
.filter--open .obb-icon-arrow-down {
  transform: rotate(180deg);
}
.filter-options {
  position: absolute;
  right: 0;
  left: 0;
  height: 0;
  overflow: hidden;
  background: #fff;
  transition: height 0.35s ease;
}
.filter--open .filter-options {
  box-shadow: 0 35px 35px -35px rgba(0, 0, 0, 0.19);
}
.filter-options__panel {
  padding-top: 5px;
  padding-bottom: 35px;
  opacity: 0;
  transition: opacity 0.25s ease;
  text-align: left;
}
.filter--open .filter-options__panel {
  opacity: 1;
  transition: opacity 0.25s ease;
}
.filter-section {
  margin-bottom: 15px;
}
@media screen and (min-width: 920px) {
  .filter-section:last-of-type {
    margin-bottom: 30px;
  }
}
.filter-section__title {
  margin-bottom: 5px;
  font-family: 'FrutigerNextBold', Arial, Helvetica, sans-serif;
}
.filter-parameter-list {
  overflow: hidden;
  list-style: none;
}
.filter-parameter-list--overview {
  vertical-align: middle;
  display: inline-block;
}
.filter-parameter-list__item {
  float: left;
  display: block;
  margin-right: 10px;
  margin-bottom: 5px;
}
.filter-parameter {
  display: inline-block;
  padding: 2px 5px;
  background: none;
  border: 1px solid #ABABAB;
  cursor: pointer;
  color: #383736;
  font-family: inherit;
  font-size: 14px;
  line-height: 28px;
}
@media screen and (min-width: 920px) {
  .filter-parameter {
    padding: 5px 10px;
    font-size: 16px;
  }
}
.filter-parameter:focus,
.filter-parameter:hover,
.filter-parameter:active {
  text-decoration: none;
}
.filter-parameter--active {
  background-color: #E2002A;
  border-color: #E2002A;
  color: #fff;
  text-decoration: none;
}
.filter-parameter--removeable {
  position: relative;
  padding-right: 35px;
}
.filter-parameter__remove {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 4px 12px;
  background-color: #E2002A;
  border: none;
  cursor: pointer;
  color: #fff;
  font-size: 20px;
}
@media screen and (min-width: 920px) {
  .filter-parameter__remove {
    padding: 8px 12px;
  }
}
.filter__button {
  display: block;
  margin: 0 auto;
  font-size: 16px;
}
@media screen and (min-width: 920px) {
  .filter__button {
    font-size: 18px;
  }
}
.filter-overview {
  margin-top: 16px;
}
@media screen and (min-width: 768px) {
  .filter-overview {
    margin-top: 0;
  }
}
.filter-reset {
  padding: 8px 0;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #E2002A;
  font-family: 'FrutigerNextBold', Arial, Helvetica, sans-serif;
}
.filter-reset [class="obb-icon-repeat"] {
  transform: rotateY(180deg);
  font-size: 16px;
}
body::after {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  transition: opacity 0.35s ease;
  pointer-events: none;
}
.search-open body::after,
.ocopen body::after {
  opacity: 1;
  z-index: 4;
}
.dialog-open body::after {
  opacity: 1;
}
.leadin {
  font-family: 'FrutigerNextMedium', Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 1.45em;
  text-align: center;
}
@media screen and (min-width: 920px) {
  .leadin {
    font-size: 22px;
  }
}
.blockquote {
  text-align: center;
  color: #383736;
  font-family: 'FrutigerNextLight', Arial, Helvetica, sans-serif;
  font-size: 22px;
  line-height: 28px;
  max-width: 570px;
  margin: 0 auto;
}
@media screen and (min-width: 920px) {
  .blockquote {
    font-size: 24px;
    line-height: 34px;
  }
}
.blockquote:before,
.blockquote:after {
  content: '';
  width: 60px;
  height: 2px;
  background: #E2002A;
  display: block;
  margin: 15px auto;
}
.small {
  font-size: 14px;
  line-height: 22px;
}
@media screen and (min-width: 920px) {
  .small {
    font-size: 16px;
    line-height: 26px;
  }
}
.breadcrumbs {
  display: none;
}
@media screen and (min-width: 768px) {
  .breadcrumbs {
    display: flex;
    list-style: none;
    font-family: 'FrutigerNextMedium', Arial, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 22px;
    padding-top: 20px;
    padding-bottom: 35px;
    z-index: 1;
    position: relative;
  }
  .breadcrumbs > li:not(:last-child) {
    white-space: nowrap;
  }
}
@media screen and (min-width: 768px) {
  .breadcrumb {
    margin-right: 5px;
  }
  .breadcrumb--active {
    color: #646463;
  }
}
@media screen and (min-width: 768px) {
  .breadcrumb__seperator {
    margin-right: 7px;
    color: #646463;
  }
}
.landingpage .breadcrumb,
.landingpage .breadcrumb__seperator {
  color: white;
  font-family: 'FrutigerNextBold', Arial, Helvetica, sans-serif;
}
.landingpage .breadcrumb--active,
.landingpage .breadcrumb__seperator--active {
  font-family: 'FrutigerNextRegular', Arial, Helvetica, sans-serif;
}
.article {
  display: block;
}
.article--category {
  margin-bottom: 30px;
}
.article__intro {
  margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
  .article__intro {
    margin-bottom: 20px;
  }
}
.article__content,
.author__content {
  background: #fff;
  padding: 25px 15px 30px;
  margin-left: -15px;
  margin-right: -15px;
}
@media screen and (min-width: 576px) {
  .article__content,
  .author__content {
    margin-left: auto;
    margin-right: auto;
    max-width: calc(100% / 12 * 10);
  }
  .article__content--intro,
  .author__content--intro {
    margin-top: -30px;
    position: relative;
    z-index: 1;
  }
  .article__content--intro-no-hero,
  .author__content--intro-no-hero {
    margin-top: 0;
  }
}
@media screen and (min-width: 920px) {
  .article__content,
  .author__content {
    padding: 55px 95px 50px;
  }
  .article__content--intro,
  .author__content--intro {
    margin-top: -80px;
  }
  .article__content--intro-no-hero,
  .author__content--intro-no-hero {
    margin-top: 0;
  }
}
.article__content.bg--transparent,
.author__content.bg--transparent {
  background: transparent;
}
.article__content p,
.author__content p {
  margin-bottom: 15px;
}
.article__content p + h2,
.article__content p + h3,
.author__content p + h2,
.author__content p + h3 {
  margin-top: 15px;
}
@media screen and (min-width: 920px) {
  .article__content p,
  .author__content p {
    margin-bottom: 30px;
  }
  .article__content p + h2,
  .article__content p + h3,
  .author__content p + h2,
  .author__content p + h3 {
    margin-top: 50px;
  }
}
.article--category .article__content p:last-child,
.article--category .author__content p:last-child {
  margin-bottom: 0;
}
.article__content ul,
.author__content ul {
  padding: 0 0 0 1em;
  margin-bottom: 30px;
}
.article__hero {
  width: 100%;
  height: 0;
  padding-top: 39.4737%;
  position: relative;
  overflow: hidden;
}
.article__hero img {
  position: absolute;
  top: 0;
  left: 0;
}
.article__category {
  margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
  .article__category {
    margin-bottom: 20px;
  }
}
.article__title {
  text-align: center;
  margin-bottom: 10px;
}
.article__release-date {
  text-align: center;
  margin-bottom: 20px;
}
.category {
  margin-bottom: 50px;
}
.category:last-child {
  margin-bottom: 0;
}
.category__title {
  margin-bottom: 15px;
}
.category__title .obb-icon-arrow-right {
  font-size: 34px;
}
.expandable__handler {
  display: block;
  width: 100%;
  padding: 8px 0;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #D6D6D6;
  font-size: 20px;
  letter-spacing: 1px;
  text-align: left;
  text-transform: uppercase;
}
.expandable__handler:focus,
.expandable__handler:hover {
  text-decoration: none;
}
.expandable__handler::after {
  float: right;
  content: '\271A';
  display: block;
  width: 22px;
  height: 22px;
  padding-left: 1px;
  background: #E2002A;
  border-radius: 50%;
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 23px;
}
.expandable--open .expandable__handler::after {
  content: '\2212';
  font-weight: bold;
  color: white;
}
/** Slider */
.carousel {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 1900px;
  margin: 0 auto 20px;
}
.carousel.hide {
  animation: hideTeaser 0.5s forwards;
}
.carousel__view {
  margin-bottom: 0.5em;
  overflow: hidden;
}
.carousel__view-wrapper,
.carousel__view,
.carousel__controls {
  position: relative;
}
.carousel__list {
  width: 100%;
  background-color: #D6D6D6;
}
.carousel--unsere-oebb .carousel__list {
  min-height: calc(100vh - 110px);
}
@media screen and (min-width: 920px) {
  .carousel--unsere-oebb .carousel__list {
    min-height: auto;
    aspect-ratio: 3.8;
  }
}
.carousel__item img {
  display: block;
  margin: 0 auto;
  width: 100%;
}
.carousel__item,
.carousel__list > div {
  left: auto;
  display: none;
  width: 100%;
}
.carousel__item--active,
.carousel__list > div.carousel__item--active {
  position: relative;
  top: 0;
  display: block;
  width: 100%;
  transform: translateX(0);
}
.carousel--unsere-oebb .carousel__item--active,
.carousel--unsere-oebb .carousel__list > div.carousel__item--active {
  position: absolute;
}
/** Animations */
.carousel__item--slide-in-left,
.carousel__list > div.carousel__item--slide-in-left {
  transform: translateX(-100%);
}
.carousel__item--slide-in-right,
.carousel__list > div.carousel__item--slide-in-right {
  transform: translateX(100%);
}
.carousel__item--slide-out-left,
.carousel__list > div.carousel__item--slide-out-left {
  transform: translateX(-100%);
}
.carousel__item--slide-out-right,
.carousel__list > div.carousel__item--slide-out-right {
  transform: translateX(100%);
}
.carousel__item.carousel__item--is-sliding,
.carousel__list > div.carousel__item--is-sliding {
  position: absolute;
  top: 0;
}
/** Forward/Backward Controls */
.carousel__control-forward,
.carousel__control-backward,
.teaser-slider__control-forward,
.teaser-slider__control-backward {
  position: absolute;
  top: 50%;
  display: none;
  width: 35px;
  height: 35px;
  cursor: pointer;
  transform: translateY(-50%);
}
.carousel__control-forward,
.teaser-slider__control-forward {
  right: 0;
}
.carousel__control-backward,
.teaser-slider__control-backward {
  left: 0;
}
.carousel__control-forward [class*='obb-icon-'],
.carousel__control-backward [class*='obb-icon-'],
.teaser-slider__control-forward [class*='obb-icon-'],
.teaser-slider__control-backward [class*='obb-icon-'] {
  position: absolute;
  top: 50%;
  left: 50%;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.65);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 28px;
  text-align: center;
  line-height: 1.15em;
}
@media screen and (min-width: 768px) {
  .carousel__control-backward,
  .carousel__control-forward,
  .teaser-slider__control-forward,
  .teaser-slider__control-backward {
    display: block;
    width: 65px;
    height: 100%;
  }
  .carousel__control-backward [class*='obb-icon-'],
  .carousel__control-forward [class*='obb-icon-'],
  .teaser-slider__control-forward [class*='obb-icon-'],
  .teaser-slider__control-backward [class*='obb-icon-'] {
    font-size: 48px;
  }
}
@media screen and (min-width: 1280px) {
  .carousel__control-forward,
  .teaser-slider__control-forward {
    transform: translate(100%, -50%) translateX(10px);
  }
  .carousel__control-backward,
  .teaser-slider__control-backward {
    transform: translate(-100%, -50%) translateX(-10px);
  }
}
/** Indicators */
.carousel__indicators {
  z-index: 1;
  display: block;
  text-align: center;
}
.carousel__indicator {
  display: inline-block;
  padding: 8px 0;
  cursor: pointer;
}
.carousel__indicator::after {
  content: '';
  display: block;
  width: 40px;
  height: 5px;
  background-color: #D6D6D6;
}
@media screen and (min-width: 576px) {
  .carousel__indicator::after {
    width: 50px;
  }
}
.carousel--unsere-oebb .carousel__indicator::after {
  width: 14px;
  height: 24px;
  background-color: #fff;
  transform: skew(-42.5deg);
  opacity: 0.5;
}
.carousel__indicator:focus::after,
.carousel__indicator:hover::after,
.carousel__indicator:active::after,
.carousel__indicator--active::after {
  background-color: #E2002A;
}
.carousel--unsere-oebb .carousel__indicator:focus::after,
.carousel--unsere-oebb .carousel__indicator:hover::after,
.carousel--unsere-oebb .carousel__indicator:active::after,
.carousel--unsere-oebb .carousel__indicator--active::after {
  background-color: #E2002A;
  opacity: 1;
}
/** Controls */
.carousel__controls {
  display: flex;
  justify-content: center;
}
.carousel--unsere-oebb .carousel__controls {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
}
.carousel__control-play {
  display: block;
  margin: 0 16px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
}
.carousel--unsere-oebb .carousel__control-play {
  position: absolute;
  left: 0;
}
.carousel__control-play:link,
.carousel__control-play:visited,
.carousel__control-play:focus,
.carousel__control-play:hover,
.carousel__control-play:active {
  text-decoration: none;
}
.carousel .carousel__icon-play {
  display: inline-block;
  font-size: 30px;
}
.carousel .carousel__icon-pause {
  display: none;
  font-size: 30px;
}
.carousel--sliding .carousel__icon-pause {
  display: inline-block;
}
.carousel--sliding .carousel__icon-play {
  display: none;
}
/** Property z-index should be higher than in Hero-Teaser elements, because Hero-Teaser can be used as items */
.carousel__control-forward,
.carousel__control-backward,
.carousel__indicators,
.teaser-slider__control-forward,
.teaser-slider__control-backward {
  z-index: 10;
}
/** Magnolia edit mode */
.edit-mode .carousel__control-forward,
.edit-mode .carousel__control-backward,
.edit-mode .teaser-slider__control-forward,
.edit-mode .teaser-slider__control-backward {
  max-height: 50%;
}
.edit-mode .carousel__item,
.edit-mode .carousel__list > div {
  display: block;
}
.edit-mode .carousel--unsere-oebb .carousel__item--active {
  position: relative !important;
}
.content-footer {
  background-color: #F5F2EF;
}
.footer {
  padding-bottom: 32px;
}
.footer__title {
  display: none;
}
@media screen and (min-width: 768px) {
  .footer__title {
    display: block;
    font-size: 18px;
    line-break: 1em;
    margin-bottom: 5px;
    font-family: 'FrutigerNextBold', Arial, Helvetica, sans-serif;
  }
}
.footer__image-credits {
  padding: 40px 0;
  color: #646463;
  font-size: 0.8em;
}
.footer__image-credits h2 {
  margin-bottom: 0.25em;
  font-size: 1.25em;
}
.footer__image-credits ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.footer__image-credits ol li {
  display: inline;
}
.footer__company {
  align-items: center;
  height: 80px;
  margin-bottom: 40px;
}
.footer__site-links {
  font-size: 16px;
}
.footer__site-links ul {
  padding: 16px 0;
  list-style: none;
}
.footer__site-links a {
  display: block;
  padding: 4px 0;
  margin: 0 0 6px;
  color: #000000;
}
@media screen and (min-width: 576px) {
  .footer__site-links {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 32px;
    /* Overriding style attribute setted by JS */
  }
  .footer__site-links > div {
    width: 50%;
    padding-right: 12px;
    margin-bottom: 24px;
  }
  .footer__site-links > div:last-child {
    padding-right: 0;
  }
  .footer__site-links ul {
    padding: 0;
  }
  .footer__site-links .expandable__content {
    height: auto !important;
    overflow: visible !important;
    opacity: 1 !important;
  }
  .footer__site-links .expandable__handler {
    display: none;
  }
}
@media screen and (min-width: 920px) {
  .footer__site-links {
    margin-bottom: 0;
  }
  .footer__site-links > div {
    width: auto;
    flex: 1 1 0px;
  }
}
.footer-company__social-links {
  text-align: right;
  list-style: none;
}
.footer-company__social-links li {
  display: inline;
}
.footer-company__social-links li a {
  display: inline-block;
  width: auto;
  padding: 4px 5px;
}
@media screen and (min-width: 576px) {
  .footer-company__social-links li a {
    padding: 4px 8px;
  }
}
.footer-company__logo {
  display: block;
  height: 50px;
}
.footer-company__logo img {
  max-height: 100%;
}
.edit-mode .footer__site-links > .mgnlEditor {
  flex: 0 1 auto;
  width: 100% !important;
}
.teaser {
  display: block;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background: #fff;
  transition: box-shadow 0.15s;
  margin-bottom: 10px;
  position: relative;
}
@media screen and (min-width: 768px) {
  .teaser {
    min-height: 300px;
    height: calc(100% - 20px);
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }
}
.teaser:hover,
.teaser:focus {
  text-decoration: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}
.no-gutters .teaser:not(:last-child) {
  margin-bottom: 0;
}
.teaser__image-wrapper {
  flex-shrink: 0;
}
.teaser__image {
  width: 100%;
  height: auto;
  display: block;
}
.teaser__desc {
  position: relative;
  padding: 10px 20px 30px;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex: 1 0 auto;
}
.teaser__headline {
  margin-bottom: 5px;
  font-size: 26px;
  line-height: 30px;
  font-family: 'FrutigerNextBold', Arial, Helvetica, sans-serif;
  color: #000000;
}
.teaser__intro {
  color: #646463;
  font-family: 'FrutigerNextMedium', Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 30px;
}
.teaser__pseudolink {
  background: none;
  border: none;
  cursor: pointer;
  color: #E2002A;
  font-family: 'FrutigerNextBold', Arial, Helvetica, sans-serif;
}
.teaser__pseudolink:hover {
  text-decoration: underline;
}
.teaser__series {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  background: #E2002A;
  color: #fff;
  padding: 0 5px;
  text-transform: uppercase;
  font-family: 'FrutigerNextMedium', Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-break: 28px;
  letter-spacing: 2px;
  transform: translateY(-75%);
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
}
.teaser--standard .teaser__pseudolink {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
}
.teaser--hero {
  overflow: hidden;
}
.blog .main > .container > .teaser--hero:first-child .teaser__image-wrapper {
  overflow: hidden;
}
@media screen and (min-width: 920px) {
  .teaser--hero {
    display: flex;
    flex-direction: row;
  }
}
.teaser--hero .teaser__image-wrapper {
  position: relative;
}
@media screen and (min-width: 920px) {
  .teaser--hero .teaser__image-wrapper {
    width: calc(100% / 12 * 8 - 10px);
  }
}
.teaser--hero .teaser__desc {
  justify-content: center;
}
@media screen and (min-width: 920px) {
  .teaser--hero .teaser__desc {
    padding: 50px 30px 30px;
    width: calc(100% / 12 * 4 - 10px);
  }
}
.teaser--hero .teaser__headline {
  font-size: 26px;
  line-height: 30px;
}
@media screen and (min-width: 920px) {
  .teaser--hero .teaser__headline {
    font-size: 36px;
    line-height: 38px;
  }
}
.teaser--hero .teaser__intro {
  margin-bottom: 10px;
}
@media screen and (min-width: 920px) {
  .teaser--hero .teaser__series {
    left: auto;
    right: 0;
    transform: none;
  }
}
@media screen and (min-width: 920px) {
  .teaser--red {
    display: flex;
  }
  .teaser--red > div {
    width: 50%;
  }
}
.teaser--red .teaser__desc {
  background: #E2002A;
  color: #fff;
}
.teaser--red .teaser__category,
.teaser--red .teaser__headline,
.teaser--red .teaser__intro {
  color: #fff;
}
.teaser--red .teaser__headline {
  margin-top: 20px;
  margin-bottom: 20px;
}
@media screen and (min-width: 920px) {
  .teaser--langer {
    display: flex;
  }
}
.teaser--langer .teaser__category {
  text-align: left;
}
.teaser--langer .teaser__desc {
  text-align: left;
}
@media screen and (min-width: 920px) {
  .teaser--langer .teaser__desc {
    width: calc(100% / 12 * 8);
  }
}
@media screen and (min-width: 920px) {
  .teaser--langer .teaser__image-wrapper {
    width: calc(100% / 12 * 4);
  }
}
.teaser--langer .teaser__intro {
  margin-bottom: 15px;
}
@media screen and (max-width: 919px) {
  .teaser--overlay {
    height: 420px;
  }
}
.teaser--overlay:not(.teaser--video) {
  height: 0;
  padding-top: 116.667%;
}
@media screen and (min-width: 920px) {
  .col-md-5th .teaser--overlay {
    height: 240px;
  }
}
.teaser--overlay .teaser__image {
  position: absolute;
  left: 50%;
  width: auto;
  max-width: none;
  height: 100%;
  transform: translateX(-50%);
}
.teaser--overlay .teaser__image-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.teaser--overlay .teaser__desc {
  text-align: center;
  color: #fff;
  position: absolute;
  padding: 25px 20px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  transition: transform 0.35s ease;
}
.teaser--overlay .teaser__desc::after {
  content: '\e315';
  opacity: 0;
  transition: opacity 0.35s ease;
  font-family: 'obb-icon' !important;
  font-size: 24px;
}
.teaser--overlay .teaser__category {
  text-align: center;
  color: #fff;
}
.teaser--overlay .teaser__pseudolink,
.teaser--overlay .teaser__intro {
  display: none;
}
.teaser--overlay .teaser__headline {
  color: #fff;
}
.teaser--overlay::before,
.teaser--overlay::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.teaser--overlay::before {
  z-index: 1;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.8) 80%);
  opacity: 1;
  transition: opacity 0.35s ease;
}
.teaser--overlay::after {
  z-index: 0;
  background: linear-gradient(180deg, rgba(226, 0, 42, 0) 0, #E2002A 80%);
  opacity: 0;
  transition: opacity 0.35s ease;
}
.teaser--overlay:hover::before {
  opacity: 0;
  transition: opacity 0.35s ease;
}
.teaser--overlay:hover::after {
  opacity: 1;
  transition: opacity 0.35s ease;
}
.teaser--overlay:hover .teaser__desc {
  transform: translateY(-10px);
  transition: transform 0.35s ease;
}
.teaser--overlay:hover .teaser__desc::after {
  opacity: 1;
  transition: opacity 0.35s ease;
}
.teaser--overlay .teaser__series {
  top: -15px;
  right: auto;
  left: 50%;
  border: none;
  transform: translateX(-50%);
}
.teaser--overlay.teaser--video .teaser__headline {
  margin: 0 50px 0 60px;
  text-align: left;
}
.teaser--overlay.teaser--video .teaser__desc {
  bottom: 20px;
}
.teaser--overlay.teaser--video .teaser__desc::before {
  position: absolute;
  top: 50%;
  left: 30px;
  content: '\e038';
  display: block;
  width: 25px;
  height: 30px;
  box-shadow: inset 0 0 0 15px #fff;
  transform: translateY(-50%);
  color: #E2002A;
  font-family: 'obb-icon' !important;
  font-size: 60px;
  line-height: 25px;
  text-indent: -20px;
}
.teaser--overlay.teaser--video .teaser__desc::after {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
.teaser--overlay.teaser--video:hover .teaser__desc {
  transform: none;
  transition: none;
}
.video-container__outer {
  position: relative;
  width: 100%;
}
.video-container {
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}
.video-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
.video-disclaimer {
  padding: 20px 20px 0;
  border: 1px solid #000000;
  margin-bottom: 20px;
}
.video__disclaimer-txt {
  margin-bottom: 10px;
}
.video__disclaimer-btn {
  margin-bottom: 20px;
}
.author {
  font-family: 'FrutigerNextMedium', Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 26px;
}
.author__content {
  background: transparent;
  text-align: center;
  position: relative;
  padding-top: 100px;
}
.author__content p {
  margin-bottom: 20px;
}
.author__img {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto -85px;
}
.author__title {
  font-family: 'FrutigerNextBold', Arial, Helvetica, sans-serif;
  font-size: 28px;
  line-height: 38px;
  margin-bottom: 15px;
}
@media screen and (min-width: 920px) {
  .author__title {
    font-size: 36px;
    line-height: 38px;
  }
}
.author__subtitle {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 1px;
  margin-bottom: 10px;
  text-transform: uppercase;
  display: block;
  font-family: 'FrutigerNextMedium', Arial, Helvetica, sans-serif;
}
.error-404 {
  padding: 70px 0 110px;
  text-align: center;
  max-width: 570px;
  margin: 0 auto;
}
.error-404__img-wrapper {
  max-width: 290px;
  width: 100%;
  margin: 0 auto 30px;
}
.error-404__code {
  display: block;
}
.error-404 p {
  margin-bottom: 25px;
}
.five-cl-teaser {
  display: block;
  list-style: none;
  column-count: 2;
  column-gap: 0;
  font-family: 'FrutigerNextLight', Arial, Helvetica, sans-serif;
  margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
  .five-cl-teaser {
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 920px) {
  .five-cl-teaser {
    column-count: 0;
    display: flex;
  }
}
.five-cl-teaser img {
  display: block;
}
.five-cl-teaser__teaser {
  position: relative;
  width: 100%;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}
@media screen and (min-width: 920px) {
  .five-cl-teaser__teaser {
    width: calc(100% / 6);
  }
}
@media screen and (max-width: 919px) {
  .five-cl-teaser__teaser:first-child,
  .five-cl-teaser__teaser:last-of-type {
    height: 0;
    padding-top: 75%;
  }
}
.five-cl-teaser__teaser--title {
  background: #fff;
  color: #000000;
  font-size: 32px;
  line-height: 1em;
}
.five-cl-teaser__teaser--title .topic__inner {
  align-items: flex-start;
}
.topic__link {
  transition: all 0.25s ease;
  display: block;
  width: 100%;
  height: 100%;
  color: #fff;
  font-size: 22px;
  line-height: 24px;
}
@media screen and (min-width: 920px) {
  .topic__link {
    position: relative;
  }
}
.topic__link:focus,
.topic__link:hover {
  z-index: 1;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  transform: scale(1.015);
}
.topic__link:before {
  content: "";
  display: block;
  width: 100%;
  height: 55%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.8) 80%);
  opacity: 1;
  transition: opacity 0.35s ease;
}
.topic__inner {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 20px;
  text-align: center;
  z-index: 2;
}
.five-cl-teaser__teaser--all .topic__inner {
  background: #E2002A;
}
.related-links {
  padding-top: 50px;
  padding-bottom: 50px;
}
.related-links__headline {
  font-family: 'FrutigerNextLight', Arial, Helvetica, sans-serif;
  font-size: 28px;
  line-height: 30px;
  margin-bottom: 25px;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .related-links__headline {
    font-size: 46px;
    line-height: 50px;
    margin-bottom: 45px;
  }
}
.pagination {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.pagination__item {
  position: relative;
  max-width: calc(50% - 10px);
}
.pagination__item--prev {
  margin-right: auto;
  padding-left: 30px;
}
.pagination__item--next {
  margin-left: auto;
  padding-right: 30px;
}
.pagination__intro {
  display: block;
  font-size: 0.8em;
  line-height: 1em;
}
.pagination__article {
  font-family: 'FrutigerNextBold', Arial, Helvetica, sans-serif;
}
@media screen and (max-width: 919px) {
  .pagination__article {
    display: none;
  }
}
.pagination__arrow {
  position: absolute;
  top: 18px;
}
@media screen and (max-width: 919px) {
  .pagination__arrow {
    top: -6px;
  }
}
.pagination__item--prev .pagination__arrow {
  left: 0;
}
.pagination__item--next .pagination__arrow {
  right: 0;
}
.sharelinks {
  padding: 50px 0 70px;
  border-top: 1px solid #D6D6D6;
}
@media screen and (min-width: 920px) {
  .sharelinks {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.sharelinks__likes-counter {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}
.sharelinks__likes-counter .button {
  margin: 0 0 10px;
}
.sharelinks__likes-counter .obb-icon-heart-square {
  font-size: 1em;
}
@media screen and (min-width: 920px) {
  .sharelinks__likes-counter {
    flex-direction: row;
    margin-bottom: 0;
  }
  .sharelinks__likes-counter .button {
    margin: 0 10px 0 0;
  }
}
.likes-counter__txt {
  transition: opacity 0.35s ease;
}
.likes-counter__number {
  font-family: 'FrutigerNextBold', Arial, Helvetica, sans-serif;
}
.sharelinks__social-media {
  display: flex;
  list-style: none;
  justify-content: center;
}
@media screen and (min-width: 920px) {
  .sharelinks__social-media {
    justify-content: flex-end;
  }
}
.social-link {
  margin-left: 10px;
  display: block;
  font-size: 0;
  width: auto;
  height: auto;
  transition: opacity 0.35s ease;
}
.social-link:before {
  font-size: 24px;
}
.social-link:hover {
  opacity: 0.75;
}
.social-link--facebook {
  color: #3B5998;
}
.social-link--twitter {
  color: #55ACEE;
}
.social-link--x-twitter {
  color: #000000;
}
.social-link--mail {
  color: #646463;
}
.social-link--linkedin {
  color: #0073B0;
}
.oebb-autocomplete-wrapper {
  width: 100%;
}
.oebb-autocomplete-wrapper input {
  width: 100%;
}
.teaser-slider {
  position: relative;
  padding: 0;
}
@media screen and (min-width: 920px) {
  .teaser-slider {
    margin: 0 70px;
  }
}
@media screen and (min-width: 1280px) {
  .teaser-slider {
    margin: 0;
  }
}
.teaser-slider__view {
  overflow: hidden;
}
.teaser-slider__items-wrapper {
  flex-wrap: nowrap;
  transition: transform 0.75s ease;
}
@media screen and (min-width: 768px) {
  .teaser-slider__control-forward {
    transform: translate(100%, -50%) translateX(10px);
  }
}
@media screen and (min-width: 768px) {
  .teaser-slider__control-backward {
    transform: translate(-100%, -50%) translateX(-10px);
  }
}
.ticker {
  background: #fff;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5), 0 2px 4px 0 rgba(0, 0, 0, 0.21);
  color: #646463;
  font-family: 'FrutigerNextMedium', Arial, Helvetica, sans-serif;
}
.ticker__head {
  background: #E7E4D8;
  padding: 4px 20px 4px 10px;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.ticker__author {
  display: flex;
  align-items: center;
}
.ticker__author-image {
  display: block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.ticker__content {
  padding: 30px 30px 20px;
  background: #fff;
  font-size: 21px;
  line-height: 30px;
}
.ticker__content > * {
  display: block;
  margin-bottom: 20px;
}
.form-outline {
  outline: 2px dotted #E2002A;
  outline-offset: 2px;
}
.is-tabbing button::-moz-focus-inner,
.no-js button::-moz-focus-inner {
  border: 0;
}
.is-tabbing a:focus,
.no-js a:focus,
.is-tabbing button:focus,
.no-js button:focus,
.is-tabbing div:focus,
.no-js div:focus,
.is-tabbing summary:focus,
.no-js summary:focus {
  outline: 2px dotted #E2002A;
  outline-offset: 2px;
  z-index: 10;
}
.is-tabbing form button:focus,
.no-js form button:focus,
.is-tabbing form input[type="submit"]:focus,
.no-js form input[type="submit"]:focus {
  outline: 2px dotted #E2002A;
  outline-offset: 2px;
}
.is-tabbing input[type="radio"]:focus + label::before,
.no-js input[type="radio"]:focus + label::before,
.is-tabbing input[type="checkbox"]:focus + label::before,
.no-js input[type="checkbox"]:focus + label::before {
  outline: 2px dotted #E2002A;
  outline-offset: 2px;
}
.is-tabbing .has-focus input[type="file"] ~ label,
.no-js .has-focus input[type="file"] ~ label {
  outline: 2px dotted #E2002A;
  outline-offset: 2px;
}
.rauten-teaser {
  position: relative;
  overflow: hidden;
}
.rauten-teaser__canvas_container {
  position: relative;
  width: 100%;
  height: 100%;
}
.rauten-teaser__canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
@media screen and (min-width: 920px) {
  .rauten-teaser__canvas_left {
    height: 140%;
    left: 0;
    bottom: 0;
    background-color: #E2002A;
    transform-origin: bottom left;
    transform: rotate(42.5deg) scaleX(-1);
  }
}
@media screen and (min-width: 920px) {
  .rauten-teaser__canvas_right {
    height: 140%;
    right: 0;
    top: 0;
    background-color: #E2002A;
    transform-origin: top right;
    transform: rotate(42.5deg) scaleX(-1);
  }
}
.rauten-teaser__img .rauten-teaser__img--variation {
  display: none;
}
@media screen and (max-width: 919px) {
  .rauten-teaser__img {
    height: calc(100vh - 110px);
  }
  .rauten-teaser__img > img {
    max-width: none;
    object-fit: cover;
    object-position: center;
    height: 100%;
  }
  .rauten-teaser__img .rauten-teaser__img--xs-max {
    display: block;
  }
  .rauten-teaser__img .rauten-teaser__img--sm-max.rauten-teaser__img--hide-xs,
  .rauten-teaser__img .rauten-teaser__img--hide-xs {
    display: none;
  }
}
@media screen and (max-width: 919px) {
  .rauten-teaser__img .rauten-teaser__img--sm-max {
    display: block;
  }
  .rauten-teaser__img .rauten-teaser__img--hide-sm {
    display: none;
  }
}
.rauten-teaser__link:hover,
.rauten-teaser__link:focus {
  text-decoration: none;
}
.rauten-teaser__text {
  position: absolute;
  bottom: 50px;
  left: 0;
  padding: 10px;
  z-index: 5;
  max-width: 415px;
  width: 100%;
}
@media screen and (min-width: 920px) {
  .rauten-teaser__text {
    left: auto;
    right: 0;
    bottom: 70px;
    transform: translateX(-40%);
  }
}
.rauten-teaser__title {
  color: #fff;
  margin-bottom: 5px;
  font-size: 32px;
  line-height: 1.25;
  font-family: 'FrutigerNextMedium', Arial, Helvetica, sans-serif;
  font-weight: 600;
}
@media screen and (min-width: 920px) {
  .rauten-teaser__title {
    font-size: calc(32px + 16 * ((100vw - 320px)/ 960));
  }
}
.rauten-teaser__subtitle {
  color: #fff;
  font-size: 18px;
  margin-bottom: 18px;
}
@media screen and (min-width: 920px) {
  .rauten-teaser__subtitle {
    font-size: 22px;
  }
}
.carousel__icon-play:before,
.carousel__icon-pause:before {
  color: white;
}
.js-playlist {
  position: relative;
  margin-bottom: 50px;
  padding-bottom: 50px;
}
.js-playlist__screen {
  display: flex;
}
.js-playlist__more-btn {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}
.js-playlist__add-btn {
  max-width: 40px;
  opacity: 1;
  white-space: nowrap;
  overflow: hidden;
  transition: all 0.5s ease;
}
.js-playlist__add-btn .obb-icon-plus {
  width: 30px;
  margin-right: 5px;
  font-size: 18px;
}
.js-playlist__add-btn:hover,
.js-playlist__add-btn:focus {
  max-width: 400px;
}
.js-playlist__item--added .js-playlist__add-btn {
  opacity: 0;
}
.custom-playlist {
  position: relative;
  width: 33.3333%;
  margin: 0 0 30px 20px;
  background: white;
  overflow-y: auto;
}
.custom-playlist__headline {
  position: sticky;
  top: 0;
  padding-top: 10px;
  background: white;
}
.custom-playlist__items {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 10px 10px;
}
.custom-playlist__item {
  position: relative;
  padding-right: 30px;
}
.custom-playlist__content {
  display: flex;
  align-items: flex-start;
  margin: 0 0 20px;
  color: #000000;
}
.custom-playlist__content:last-child {
  margin-bottom: 0;
}
.custom-playlist__content-thumb {
  width: 35%;
  margin-right: 5px;
}
.custom-playlist__content-title {
  font-family: 'FrutigerNextBold', Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.2em;
}
.custom-playlist__content-channel {
  font-size: 14px;
  line-height: 1.2em;
  font-family: 'FrutigerNextLight', Arial, Helvetica, sans-serif;
}
.custom-playlist__remove-btn {
  position: absolute;
  right: 0;
  top: 0;
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.gallery {
  position: relative;
  min-height: 100px;
}
.gallery__inner {
  display: flex;
  flex-wrap: wrap;
  opacity: 0;
  transition: opacity 0.35s ease-in;
  position: relative;
}
.edit-mode .gallery__inner,
.gallery--ready .gallery__inner {
  opacity: 1;
}
.gallery__loader {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 60px;
  height: 60px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.25s ease-out;
  animation: rotating 2s linear infinite;
  transform-origin: center center;
  font-size: 40px;
}
.edit-mode .gallery__loader,
.gallery--ready .gallery__loader {
  opacity: 0;
}
.gallery__item {
  overflow: hidden;
  border: 1px solid white;
  position: relative;
}
.gallery__thumb {
  display: block;
  height: 130px;
  width: auto;
  max-width: none;
  transform: scale3d(1, 1, 1);
  transition: transform 0.15s ease 0s;
}
.gallery__item:hover .gallery__thumb {
  transform: scale3d(1.1, 1.1, 1.1);
}
.item__subnote {
  display: block;
}
.thumb__poster {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  transition: opacity 0.25s ease-in;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.gallery__item:hover .thumb__poster {
  opacity: 0.5;
}
.thumb__caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #000;
  opacity: 0;
  color: white;
  text-decoration: none;
  transition: opacity 0.25s ease-in;
  font-size: 12px;
  padding: 8px;
  line-height: 1.2em;
}
.gallery__item:hover .thumb__caption {
  opacity: 0.9;
}
.landingpage__hero {
  overflow: hidden;
  height: 0;
  padding-top: calc(580 / 1920 * 100%);
  position: relative;
  margin-bottom: 50px;
}
@media screen and (min-width: 768px) {
  .landingpage__hero {
    margin-top: -80px;
    z-index: 0;
  }
}
.landingpage__hero img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.landingpage__hero:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: unset;
  right: 0;
}
@media screen and (min-width: 768px) {
  .landingpage__hero:before {
    height: 140%;
    left: 0;
    bottom: 0;
    background-color: #E2002A;
    transform-origin: bottom left;
    transform: rotate(42.5deg) scaleX(-1);
    z-index: 2;
  }
}
.landingpage__hero:after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}
@media screen and (min-width: 768px) {
  .landingpage__hero:after {
    height: 140%;
    left: 0;
    bottom: 0;
    background-color: #E2002A;
    transform-origin: top right;
    transform: rotate(42.5deg) scaleX(-1);
  }
}
.landingpage__hero .landingpage__hero-text {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  display: flex;
  align-items: flex-end;
  padding: 15px;
}
@media screen and (min-width: 768px) {
  .landingpage__hero .landingpage__hero-text {
    width: 500px;
    align-items: center;
  }
}
.landingpage__hero .landingpage__title {
  color: white;
  z-index: 1;
}
.template-waterfall .main {
  padding-left: 0;
  padding-right: 0;
  max-width: 100%;
}
.waterfall__section {
  min-height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.waterfall__section--first {
  min-height: calc(100vh - 50px);
}
@media screen and (min-width: 920px) {
  .waterfall__section--first {
    min-height: calc(100vh - 88px);
  }
}
.edit-mode .waterfall__section {
  display: block;
}
.waterfall__effect {
  padding: 20px;
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 920px) {
  .half-w {
    width: 50%;
  }
}
@media screen and (min-width: 920px) {
  .half-h {
    height: 50%;
  }
}
.scrollIn {
  position: absolute;
  top: 0;
  left: 0;
}
.edit-mode .scrollIn {
  position: static;
}
.scrollInRight {
  position: absolute;
  top: 0;
  right: 0;
}
.edit-mode .scrollInRight {
  position: static;
}
.scrollInLeft {
  position: absolute;
  top: 0;
  left: 0;
}
.edit-mode .scrollInLeft {
  position: static;
}
.waterfall--flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-direction: column;
}
.waterfall__bg {
  background-size: cover;
  background-position: 50% 50%;
}
.waterfall__bg--red {
  background-color: #E2002A;
  color: white!important;
}
.waterfall__bg--white {
  background-color: white;
}
.socialmedia-wall {
  text-align: center;
}
.socialmedia-wall__disclaimer {
  text-align: center;
}
.socialmedia-wall__disclaimer p {
  margin-bottom: 8px;
}
.scroll-top {
  border-radius: 50%;
  z-index: 5;
  background: white;
  box-shadow: 0 0px 20px rgba(0, 0, 0, 0.28);
  text-align: center;
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 40px;
  height: 40px;
  font-size: 35px;
}
@media screen and (min-width: 768px) {
  .scroll-top {
    width: 60px;
    height: 60px;
    font-size: 55px;
  }
}
@media screen and (min-width: 1280px) {
  .scroll-top {
    bottom: 20px;
    right: 20px;
    width: 70px;
    height: 70px;
    font-size: 65px;
  }
}
.scroll-top:hover,
.scroll-top:focus {
  background: #E2002A;
  color: white;
}
.scroll-top .obb-icon-arrow-up {
  font-size: 1em;
  width: 100%;
  height: 100%;
}
.button-teaser {
  position: relative;
  text-align: center;
}
.button-teaser__img-wrapper {
  display: inline-block;
  position: relative;
}
.button-teaser__img-wrapper:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.57);
  z-index: 1;
}
.button-teaser__img {
  display: block;
}
.button-teaser__button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
@media screen and (max-width: 767px) {
  .button-teaser__button {
    width: 80%;
    font-size: 14px;
    padding: 12px 14px;
  }
}
.jobprofile {
  margin-bottom: 30px;
  background: white;
}
.jobprofile__image-container {
  text-align: center;
  height: 100%;
}
.jobprofile__image-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
.jobprofile__image-wrapper:before,
.jobprofile__image-wrapper:after {
  content: "";
  display: block;
  width: 35%;
  max-width: 150px;
  height: 100%;
  position: absolute;
  top: 0;
  transform-origin: bottom;
  background: white;
}
.jobprofile__image-wrapper:before {
  transform: translateX(-100%) skew(-30deg);
  left: 0;
}
.jobprofile__image-wrapper:after {
  transform: skew(-30deg);
  right: 0;
}
.jobprofile__image {
  display: block;
  min-height: 100%;
}
.jobprofile__content {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'FrutigerNextLight', Arial, Helvetica, sans-serif;
  padding: 15px;
}
.jobprofile__text {
  font-size: 24px;
  margin-bottom: 10px;
}
@media screen and (min-width: 920px) {
  .jobprofile__text {
    font-size: 32px;
  }
}
.jobprofile__subtext {
  font-size: 14px;
}
@media screen and (min-width: 920px) {
  .jobprofile__subtext {
    font-size: 18px;
  }
}
details {
  background: #fff;
  border-bottom: 1px solid #ABABAB;
  list-style: none;
}
details summary {
  display: flex;
  padding: 12px;
  cursor: pointer;
  font-family: 'FrutigerNextBold', Arial, Helvetica, sans-serif;
}
details summary::-webkit-details-marker {
  display: none;
}
details summary::before {
  content: '';
  display: block;
  width: 28px;
  height: 28px;
  margin-right: 10px;
  margin-left: -4px;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 32 32"><path d="M9.875 11.104l6.125 6.125 6.125-6.125 1.875 1.875-8 8-8-8z"></path></svg>');
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  transform: rotate(0);
}
details[open] > summary::before {
  transform: rotate(-180deg);
}
details > div {
  padding: 0 12px 14px 46px;
}
details > div > :last-child {
  margin-bottom: 0;
}
.cta {
  display: grid;
  margin-bottom: 20px;
}
.cta__call {
  margin-bottom: 12px;
  font-size: 1.75em;
}
@media screen and (max-width: 919px) {
  .cta__call {
    font-size: 1.25em;
  }
}
.cta__action {
  font-size: 1.25em;
}
.cta-picture,
.cta-text {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
}
.cta-text {
  display: flex;
  align-items: center;
}
.cta-text--content-left {
  justify-content: flex-start;
}
.cta-text--content-center {
  justify-content: center;
}
.cta-text--content-right {
  justify-content: flex-end;
}
@media (max-width: 919px) {
  .cta-text--content-left,
  .cta-text--content-center,
  .cta-text--content-right {
    justify-content: center;
    text-align: center;
  }
}
.cta-picture {
  display: block;
  width: 100%;
  height: 100%;
}
.cta-picture img,
.cta-picture source {
  width: 100%;
  height: 100%;
  margin: 0;
  object-fit: contain;
  object-position: left bottom;
}
.cta-picture img {
  display: block;
}
.cta picture ~ .cta-text .cta-text__container,
.cta img ~ .cta-text .cta-text__container {
  margin-top: -4%;
  padding: 8px 24px;
}
.cta picture ~ .cta-text .cta__call,
.cta img ~ .cta-text .cta__call {
  margin-top: 0;
  margin-bottom: 12px;
  color: #fff;
  font-weight: bold;
  text-shadow: 1px 1px 1px #000;
}
.cta picture ~ .cta-text .cta__action,
.cta img ~ .cta-text .cta__action {
  margin-bottom: 0;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
/** Override file for small changes in specific sites */
.openinnovation .logo-wrapper--custom .logo__item {
  width: 120px;
  padding: 5px 0;
}
@media screen and (min-width: 920px) {
  .openinnovation .logo-wrapper--custom .logo__item {
    width: 150px;
  }
}
