
.blockquote {
    text-align: center;
    color: @black-90;
    font-family: @font-light;
    font-size: 22px;
    line-height: 28px;
    max-width: 570px;
    margin: 0 auto;

    @media @screen-md-min {
        font-size: 24px;
        line-height: 34px;
    }

    &:before,
    &:after {
        content: '';
        width: 60px;
        height: 2px;
        background: @oebb-red;
        display: block;
        margin: 15px auto;
    }
}