
.button {
    display: inline-block;
    font-family: @font-bold;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    padding: 16px 20px;
    border: none;
    border-radius: 0;
    cursor: pointer;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

// Changed to use as mixin
.button--primary {
    background: @oebb-red;
    color: @white;

    &:hover,
    &:focus,
    &:active {
        background: @dark-red;
    }

    &-disabled,
    &:disabled,
    &[disabled] {
        background: @black-80;
        color: @white;
        pointer-events: none;
    }
}

.button {
    &--secondary {
        border: 1px solid @oebb-red;
        color: @oebb-red;
        background: @white;

        &:hover,
        &:focus,
        &:active {
            background: @oebb-red;
            color: @white;
        }
    }

    &:disabled,
    &--secondary-disabled,
    &--secondary[disabled] {
        border: 1px solid @black-80;
        color: @black-80;
        pointer-events: none;
        background: @white;
    }

    &--inverted {
        border: 1px solid @white;
        background: transparent;
        color: @white;

        &:hover,
        &:focus,
        &:active {
            background: @white;
            color: @oebb-red;
        }
    }

    &:disabled,
    &--inverted-disabled,
    &--inverted[disabled] {
        border: 1px solid @black-10;
        background: transparent;
        color: @black-10;
    }

    &--monochrom {
        background: @white;
        color: @black-100;
    }

    &:disabled,
    &--monochrom-disabled,
    &--monochrom[disabled] {
        background: @black-60;
        color: @white;
    }

    [class*="obb-icon-"] {
        width: 1em;
        height: 1em;

        font-size: 18px;
    }

    &--link {
        padding: 0;
        border: none;
        background: transparent;
        color:  @oebb-red;

        &:hover,
        &:focus,
        &:active {
            color: @oebb-red;
            text-decoration: underline;
        }

        .oebb-dialog & {
            padding: 0 20px 20px 0;
        }
    }

    &--align-right {
        display: block;
        margin-left: auto;
    }
}