form,
.form {
    margin: 0;
    padding: 0;

    p,
    fieldset,
    .control {
        margin: 0 0 1em;
        padding: 0;
    }

    fieldset {
        border: none;
    }

    &--disabled {
        position: relative;

        &::before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;

            content: '';
            display: block;
            width: 100%;
            height: 100%;

            background: rgba(255, 255, 255, 0.15);
        }
    }
}

legend,
label {
    display: block;
    padding: 6px 0;

    color: @black-90;
}

input,
textarea {
    outline: none;
}

input::-webkit-input-placeholder {
    line-height: normal !important;
}

textarea {
    resize: vertical;
}

.control-text,
input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="date"],
input[type="file"],
textarea {
    display: inline-block;

    padding: 15px 15px;

    background: @white;
    border: none;
    border-radius: 0;
    box-shadow: inset 0 -1px 0 @black-70;

    &:focus {
        box-shadow: inset 0 -2px 0 @oebb-red;
    }

    &:disabled,
    &[disabled] {
        background-color: @black-15;
    }

    .control-has-error & {
        border: 1px solid @error;
    }

    .control-full-width &,
    .form--controls-full-width & {
        width: 100%;
    }

    &.gray {
        background-color: @black-10;
    }
}

input[type="radio"],
input[type="checkbox"] {
    position: absolute;

    display: block;
    width: 0;
    height: 0;
    margin-bottom: 0;

    opacity: 0;

    & + label {
        position: relative;

        display: inline-block;
        margin-left: 35px;
    }

    & + label::before {
        position: absolute;
        top: 10px;
        left: -35px;

        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;

        background: @white;
        border: 0.35em solid @white;
        box-shadow: 0 0 0 1px @black-70;
    }

    &:focus + label::before {
        background: @black-60;
    }

    &:disabled,
    &[disabled] {
        & + label::before {
            background: @black-15;
            border-color: @black-15;
        }
    }
}

input[type="radio"] {
    & + label::before {
        border-radius: 50%;
    }

    & + label:hover::before {
        background: @black-60;
    }

    &:checked + label::before {
        background: @black-90;
    }
}

input[type="checkbox"] {
    & + label:hover::before {
        content: '\e629';

        color: @black-60;
        line-height: 8px;
        text-indent: -5px;
        font-family: 'obb-icon' !important;
    }

    &:checked + label::before {
        content: '\e629';

        line-height: 8px;
        text-indent: -5px;
        font-family: 'obb-icon' !important;
    }

    &:focus + label::before {
        background: @white;
    }

    &:checked + label:hover::before {
        content: '\e629';

        color: @black-90;
    }
}

input[type="submit"] {
    .button();
    .button--primary();

    &:disabled {
        border: 1px solid @black-80;
        color: @black-80;
        pointer-events: none;
        background: @white;
    }
}

input[type="file"] {
    cursor: pointer;
}

.control-group {
    display: flex;
}

.control-assistive {
    display: block;
    margin-top: -10px;
    margin-bottom: 6px;

    color: @black-80;
    font-size: 0.85em;

    &--success {
        color: @success;
    }

    &--error {
        color: @error;
        font-weight: bold;
    }
}
