
.related-links {
    padding-top: 50px;
    padding-bottom: 50px;
}

.related-links__headline {
    font-family: @font-light;
    font-size: 28px;
    line-height: 30px;
    margin-bottom: 25px;
    text-align: center;
    
    @media @screen-sm-min {
        font-size: 46px;
        line-height: 50px;
        margin-bottom: 45px;
    }
}