.form-outline {
    outline: 2px dotted @oebb-red;
    outline-offset: 2px;
}

.is-tabbing,
.no-js {
    button::-moz-focus-inner {
        border: 0;
    }

    a:focus,
    button:focus,
    div:focus,
    summary:focus {
        outline: 2px dotted @oebb-red;
        outline-offset: 2px;

        z-index: 10;
    }

    form {
        button:focus,
        input[type="submit"]:focus {
            .form-outline();
        }
    }

    input[type="radio"],
    input[type="checkbox"] {
        &:focus + label::before {
            .form-outline();
        }
    }

    .has-focus input[type="file"] {
        ~ label {
            .form-outline();
        }
    }
}
