.search-box {
    width: 100%;
    background: @white;
    padding: 20px 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    pointer-events: none;
    transition: opacity .35s ease;
    z-index: 5;

    .search-open & {
        opacity: 1;
        pointer-events: auto;

        .dialogShadow();
    }

    &--attached {
        position: static;
        left: auto;

        opacity: 1;
        transform: none;
        pointer-events: auto;
    }

    @media @screen-md-min {
        font-size: 28.6px;
        padding: 35px 0;
    }
}

.search-box__title {
    color: @black-80;
    font-family: @font-light;
    font-size: 18.6px;
    line-height: 1em;
    margin-bottom: 12px;

    @media @screen-md-min {
        font-size: 28.6px;
    }
}

.search-form {

    &__control {
        position: relative;

        @media @screen-sm-min {
            display: flex;
            align-items: flex-start;
        } 
    }

    &__input {
        height: 50px;
        border: none;
        -webkit-appearance: none;
        background-color: @black-10;
        padding: 18px 15px 15px;
        font-size: 18px;
        line-height: 1em;
        font-family: @font-regular;
        border-left: 1px solid @black-10 !important; // Fix 1px line on the left of input

        &:focus {
            outline: none;
        }
    }

    &__submit {
        -webkit-appearance: none;
        border: none;
        color: @white;
        font-family: @font-bold;
        font-size: 18px;
        line-height: 1em;
        text-align: center;
        padding: 16px;
        flex-basis: 15%;
        background: @oebb-red;
        position: absolute;
        top: 0;
        right: 0;

        &:hover,
        &:focus {
            background: @dark-red;
            cursor: pointer;
        }

        @media @screen-sm-min {
            position: static;
        }
    }
}

.sortbutton-wrapper {
    display: none;
    justify-content: flex-end;
    padding-top: 20px;
}

.sortbutton {
    margin-left: 15px;
    padding: 2px 4px;
    color: @black-90;
    text-transform: uppercase;

    &--active {
        color: @white;
        background-color: @black-80;
    }

}