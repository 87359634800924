.template-waterfall .main {
    padding-left: 0;
    padding-right: 0;
    max-width: 100%;
}


.waterfall__section {
    min-height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;

    &--first {
        min-height: ~'calc(100vh - 50px)'; // Mobile Header-Height

        @media @screen-md-min {
            min-height: ~'calc(100vh - 88px)'; // MD Header-Height
        }
    }

    .edit-mode & {
        display: block;
    }
}

.waterfall__effect {
    padding: 20px;
    width: 100%;
    height: 100%;
}

.half-w {
    @media @screen-md-min {
        width: 50%;
    }
}

.half-h {
    @media @screen-md-min {
        height: 50%;
    }
}

.scrollIn {
    position:  absolute;
    top: 0;
    left: 0;

    .edit-mode & {
        position: static;
    }
}

.scrollInRight {
    position:  absolute;
    top: 0;
    right: 0;

    .edit-mode & {
        position: static;
    }
}

.scrollInLeft {
    position:  absolute;
    top: 0;
    left: 0;

    .edit-mode & {
        position: static;
    }
}

.waterfall--flex {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    flex-direction: column;
}

.waterfall__bg {
    background-size: cover;
    background-position: 50% 50%;

    &--red {
        background-color: @oebb-red;
        color: white!important;
    }

    &--white {
        background-color: white;
    }
}
