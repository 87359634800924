/** Override file for small changes in specific sites */

.openinnovation {

	.logo-wrapper--custom .logo__item {
		width: 120px;
		padding: 5px 0;

		@media @screen-md-min {
            width: 150px;
        }
	}

}
