@keyframes rotating {
	from {
	  transform: rotate(0deg);
	}
	to {
	  transform: rotate(360deg);
	}
  }

.gallery {
	position: relative;
	min-height: 100px;
}

.gallery__inner {
	display: flex;
	flex-wrap: wrap;
	opacity: 0;
	transition: opacity .35s ease-in;
	position: relative;

	.edit-mode &,
	.gallery--ready & {
		opacity: 1;
	}
}

.gallery__loader {
	position: absolute;
	top: 100px;
	left: 50%;
	transform: translate(-50%,0);
	width: 60px;
	height: 60px;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: opacity .25s ease-out;

	animation: rotating 2s linear infinite;
	transform-origin: center center;
	font-size: 40px;

	.edit-mode &,
	.gallery--ready & {
		opacity: 0;
	}
	
}



.gallery__item {
	overflow: hidden;
	border: 1px solid white;
	position: relative;
}

.gallery__thumb {
	display: block;
	height: 130px;
	width: auto;
	max-width: none;

	transform: scale3d(1, 1, 1);
	transition: transform 0.15s ease 0s;

	.gallery__item:hover & {
		transform: scale3d(1.1, 1.1, 1.1);
	}
}

.item__subnote {
	display: block;
}

.thumb__poster {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000;
	opacity: 0;
	transition: opacity .25s ease-in;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;

	.gallery__item:hover & {
		opacity: .5;
	}
}

.thumb__caption {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	background: #000;
	opacity: 0;
	color: white;
	text-decoration: none;
	transition: opacity .25s ease-in;
	font-size: 12px;
	padding: 8px;
	line-height: 1.2em;

	.gallery__item:hover & {
		opacity: .9;
	}
}

