.expandable {
    &__handler {
        display: block;
        width: 100%;
        padding: 8px 0;

        background: transparent;
        border: 0;
        border-bottom: 1px solid @black-20;

        font-size: 20px;
        letter-spacing: 1px;
        text-align: left;
        text-transform: uppercase;

        &:focus,
        &:hover {
            text-decoration: none;
        }
    }

    &__handler::after {
        float: right;

        content: '\271A';
        display: block;
        width: 22px;
        height: 22px;
        padding-left: 1px;

        background: @oebb-red;
        border-radius: 50%;

        color: @white;
        font-size: 14px;
        text-align: center;
        line-height: 23px;
    }

    &--open {
        .expandable__handler::after {
            content: '\2212';

            font-weight: bold;
            color: white;
        }
    }
}
