
.button-teaser {
    position: relative;
    text-align: center;
}

.button-teaser__img-wrapper {
    display: inline-block;
    position: relative;

    &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;

        background: rgba(0,0,0,.57);
        z-index: 1;
    }
}

.button-teaser__img {
    display: block;
}

.button-teaser__button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 2;

    @media @screen-xs-max {
      width: 80%;
      font-size: 14px;
      padding: 12px 14px;  
    }
}