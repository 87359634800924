details {
	background: @white;
	border-bottom: 1px solid @black-60;
	list-style: none;

	summary {
		display: flex;
		padding: 12px;

		cursor: pointer;

		font-family: @font-bold;

		&::-webkit-details-marker {
			display: none;
		}

		&::before {
			content: '';
			display: block;
			width: 28px;
			height: 28px;
			margin-right: 10px;
			margin-left: -4px;

			background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 32 32"><path d="M9.875 11.104l6.125 6.125 6.125-6.125 1.875 1.875-8 8-8-8z"></path></svg>');
			background-position: center;
			background-repeat: no-repeat;
			background-attachment: scroll;

			transform: rotate(0);
			// transition: 0.25s transform ease;
		}
	}

	&[open] > summary::before {
		transform: rotate(-180deg);
	}

	> div {
		padding: 0 12px 14px 46px;

		& > :last-child {
			margin-bottom: 0;
		}
	}
}
