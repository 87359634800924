
.five-cl-teaser {
    display: block;
    list-style: none;
    column-count: 2;
    column-gap: 0;
    font-family: @font-light;
    margin-bottom: 10px;

    @media @screen-sm-min {
        margin-bottom: 20px;  
    }

    @media @screen-md-min {
        column-count: 0;
        display: flex;
    }

    img {
        display: block;
    }

    &__teaser {
        position: relative;
        width: 100%;
        -webkit-column-break-inside: avoid;
          page-break-inside: avoid;
               break-inside: avoid;

        @media @screen-md-min { 
            width: ~'calc(100% / 6)';
        }

        @media @screen-sm-max {
            &:first-child,
            &:last-of-type {
                height: 0;
                padding-top: 75%;
            }
        }

        &--title {
            background: @white;
            color: @black-100;
            font-size: 32px;
            line-height: 1em;

            .topic__inner {
                align-items: flex-start;
            }
        }
    }
}

// Topic
.topic__link {
    transition: all .25s ease;
    display: block;
    width: 100%;
    height: 100%;
    color: @white;
    font-size: 22px;
    line-height: 24px;

    @media @screen-md-min { 
        position: relative;
    }

    &:focus ,
    &:hover {
        z-index: 1;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
        transform: scale(1.015);
    }

    &:before {
        content: "";
        display: block;
        width: 100%;
        height: 55%;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        background: linear-gradient(180deg,rgba(0,0,0,0) 0,rgba(0,0,0,.8) 80%);
        opacity: 1;
        transition: opacity .35s ease;
    }
}

.topic__inner {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 20px;
    text-align: center;
    z-index: 2;

    .five-cl-teaser__teaser--all & {
        background: @oebb-red;
    }
}



