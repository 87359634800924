
.error-404 {
    padding: 70px 0 110px;
    text-align: center;
    max-width: 570px;
    margin: 0 auto;

    &__img-wrapper {
        max-width: 290px;
        width: 100%;
        margin: 0 auto 30px
    }

    &__code {
        display: block;
    }

    p {
        margin-bottom: 25px;
    }
}