
// Burger link
.burger-link {
    order: -1;
    margin-right: 15px;

    @media @screen-md-min {
        display: none;
    }
}

// Wrapper for menu
.main-menu {
    @media @screen-sm-max {
        position: fixed;
        top: 0;
        left: 0;
        width: 275px;
        height: 100%;
        transform: translateX(-100%);
        background: linear-gradient(180deg, @oebb-red 60px, @bg-grey 60px);
        box-shadow: 0 1px 2px 0 rgba(0,0,0,0.18);
        z-index: 5;
        transition: transform .35s ease;

        .ocopen & {
            transform: translateX(0%);
        }
    }

    @media @screen-xs-min {
        width: 320px;
    }

    @media @screen-md-min {
        width: auto;
    }
}

// First list for navigation
.main-nav {
    list-style: none;

    @media @screen-sm-max {
        margin-top: 50px;
    }

    @media @screen-md-min {
        display: flex;
        align-items: center;
        min-height: 55px;
        margin-top: 0;

        .header--nav-inline & {
            min-height: 45px;
        }
    }
}

// List element in main navigation
.main-nav__list-item {
    position: relative;

    @media @screen-md-min {
        margin-right: 25px;

        &--has-sublevel {
            margin-right: 15px;
        }

        &--home {
            display: none;
        }
    }

    @media @screen-lg-min {
        margin-right: 35px;

        &--has-sublevel {
            margin-right: 25px;
        }
    }

    &--current-active > a { // Current page is active in browser and currently on screen
        text-decoration: underline;
    }
    &--current-open > a { // Subpage is active
        text-decoration: underline;
    }
}

// Link in main navigation
.main-item {
    display: inline-block;
    padding: 8px 0;

    font-size: 18px;
    font-family: @font-light;
    color: @black-100;

    @media @screen-sm-max {
        line-height: 50px;
        text-transform: uppercase;
        padding: 0 12px;
        border-bottom: 1px solid @black-20;
        background: @white;
        display: block;
    }

    @media @screen-md-min {
        line-height: 1em;
        font-size: 18px;
    }

    @media @screen-lg-min {
        font-size: 18px;
    }
}

// Button in 1st level to toggle second level
.main-item__toggler {
    display: inline-block;

    background: none;
    border: none;
    border-left: 1px solid @black-20;
    transform: translateX(-5px);
    cursor: pointer;

    color: @black-100;
    text-align: center;

    @media @screen-sm-max {
        position: absolute;
        top: 1px;
        right: 0;

        display: block;
        width: 50px;
        height: 48px;

        line-height: 48px;
    }

    @media @screen-md-min {
        margin-left: 8px;
    }

    .obb-icon-arrow-down {
        display: inline-block;
    }

    .obb-icon-arrow-up {
        display: none;
    }

    .main-nav__list-item--open &,
    .main-nav__list-item--open-keep & {
        .obb-icon-arrow-down {
            display: none;
        }

        .obb-icon-arrow-up {
            display: inline-block;
        }
    }

    @media @screen-sm-max {
        .main-nav__list-item--current-open & {
            .obb-icon-arrow-down {
                display: none;
            }

            .obb-icon-arrow-up {
                display: inline-block;
            }
        }
    }
}

// Close Button
.main-nav__close {
    display: none;

    @media @screen-sm-max {
        display: block;
        position: absolute;
        right: 0;
        width: 50px;
        height: 50px;
        z-index: 5;
        min-height: 50px;
        text-align: center;
        line-height: 48px;
        color: @white;

        i {
            vertical-align: middle;
        }
    }
}

// Wrapper for 2nd level lists
.main-nav__sublevel {
    display: none;

    @media @screen-sm-max {
        display: none;
        background: @black-20;
        font-size: 16px;
        line-height: 2em;
    }

    ul {
        list-style: none;
    }
}

// Open 2nd level on off canvas menu
.main-nav__list-item--current-open .main-nav__sublevel,
.main-nav__list-item--open .main-nav__sublevel,
.main-nav__list-item--open-keep .main-nav__sublevel {
    @media @screen-sm-max {
        display: block;
        background-color: #f3f3f3;
    }
}

// Open 2nd level on desktop menuss
.main-nav__list-item--open .main-nav__sublevel,
.main-nav__list-item--open-keep .main-nav__sublevel,
.no-js .main-nav__list-item > a:hover ~ .main-nav__sublevel,
.no-js .main-nav__sublevel:hover {
    @media @screen-md-min {
        position: absolute;
        left: 0;

        display: block;
        min-width: 150px;

        background-color: @white;
        border: 1px solid @black-20;
        border-radius: 5px;
        transform: translate(-2px, 0);
    }
}

// .main-nav__list-item > a:hover + button {
//     & > .obb-icon-arrow-down {
//         display: none,
//     }

//     & > .obb-icon-arrow-up {
//         display: inline-block;
//     }
// }

// General sublevel item
.sublevel-item {
    position: relative;

    display: block;

    border-bottom: 1px solid @black-20;

    color: @black-100;
    text-decoration: none;

    @media @screen-sm-max {
        padding: 4px 50px 4px 12px;
    }

    @media @screen-md-min {
        padding: 10px 14px;

        border-color: @black-20;
    }
}

// .sublevel-item__toggler {
//     @media @screen-sm-max {
//         position: absolute;
//         top: 0;
//         right: 0;
//         width: 36px;
//         height: 100%;
//         background: none;
//         border: none;
//         border-left: 1px solid @bg-grey;
//         cursor: pointer;
//         color: @white;
//     }
// }

//wrapper links 3rd level
// .main-nav__sub-sublevel {
//     @media @screen-sm-max {
//         position: fixed;
//         top: 50px;
//         left: 0;
//         width: 100%;
//         height: 100%;
//         transform: translateX(-100%);
//         transition: transform .35s ease;
//         background: @bg-grey;
//         z-index: 4;

//         .sublevel__list-item--open & {
//             transform: translateX(0%);
//         }
//     }
// }

// .sublevel__title {
//     @media @screen-sm-max {
//         position: absolute;
//         right: 55px;
//         left: 50px;
//         top: 0;
//         display: block;
//         height: 50px;
//         overflow: hidden;
//         color: @white;
//         font-size: 18px;
//         line-height: 50px;
//         text-align: left;
//         white-space: nowrap;
//         text-overflow: ellipsis;
//         z-index: 4;
//     }
// }

// .sublevel__btn-back {
//     @media @screen-sm-max {
//         position: absolute;
//         display: block;
//         width: 50px;
//         height: 50px;
//         z-index: 4;
//         top: 0;
//         left: 0;
//         padding: 0 14px;
//         background-color: transparent;
//         border: none;
//         color: @white;
//     }
// }

// Sitemap
.sitemap {
    padding-left: 20px;

    &__sublevel {
        padding-left: 20px;
    }
}