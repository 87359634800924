
.pagination {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.pagination__item {
    position: relative;
    max-width: ~'calc(50% - 10px)';

    &--prev {
        margin-right: auto;
        padding-left: 30px;
    }

    &--next {
        margin-left: auto;
        padding-right: 30px;
    }
}

.pagination__intro {
    display: block;
    font-size: .8em;
    line-height: 1em;
}

.pagination__article {
    font-family: @font-bold;

    @media @screen-sm-max {
        display: none;
    }
}

.pagination__arrow {
   position: absolute;
   top: 18px;

   @media @screen-sm-max {
    top: -6px;
}

   .pagination__item--prev & {
    left: 0;
   }

   .pagination__item--next & {
        right: 0;
    }
}

