.footer {
    padding-bottom: 32px;

    &__title {
        display: none;

        @media @screen-sm-min {
            display: block;
            font-size: 18px;
            line-break: 1em;
            margin-bottom: 5px;
            font-family: @font-bold;
        }

    }

    &__image-credits {
        padding: 40px 0;

        color: @black-80;
        font-size: 0.8em;

        h2 {
            margin-bottom: 0.25em;
            font-size: 1.25em;
        }

        ol {
            margin: 0;
            padding: 0;

            list-style: none;

            li {
                display: inline;
            }
        }
    }

    &__company {
        align-items: center;

        height: 80px;  // min-height breaks "align-items: center" in IE11
        margin-bottom: 40px;
    }

    &__site-links {
        font-size: 16px;

        ul {
            padding: 16px 0;

            list-style: none;
        }

        a {
            display: block;
            padding: 4px 0;
            margin: 0 0 6px;

            color: @black-100;
        }

        @media @screen-xs-min {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 32px;

            > div {
                width: 50%;
                padding-right: 12px;
                margin-bottom: 24px;

                &:last-child {
                    padding-right: 0;
                }
            }

            ul {
                padding: 0;
            }

            /* Overriding style attribute setted by JS */

            .expandable__content {
                height: auto !important;
                overflow: visible !important;

                opacity: 1 !important;
            }
            .expandable__handler {
                display: none;
            }
        }

        @media @screen-md-min {
            margin-bottom: 0;

            > div {
                width: auto;
                flex: 1 1 0px; // Use "px" as IE11 bug fix
            }
        }
    }
}

.footer-company {
    &__social-links {
        text-align: right;

        list-style: none;
    }

    &__social-links li {
        display: inline;

    }

    &__social-links li a {
        display: inline-block;
        width: auto;
        padding: 4px 5px;

        @media @screen-xs-min {
            padding: 4px 8px;
        }
    }

    &__logo {
        display: block;

        height: 50px;

        img {
            max-height: 100%;
        }
    }
}

.edit-mode .footer__site-links > .mgnlEditor {
    flex: 0 1 auto;
    width: 100% !important;
}
