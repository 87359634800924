.cta {
    display: grid;
    margin-bottom: 20px;

    &__call {
		margin-bottom: 12px;

        font-size: 1.75em;

        @media @screen-sm-max {
            font-size: 1.25em;
        }
    }

    &__action {
        font-size: 1.25em;
    }

    &-picture,
    &-text {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
    }


    &-text {
        display: flex;
        align-items: center;

        &--content-left {
            justify-content: flex-start;
        }

        &--content-center {
            justify-content: center;
        }

        &--content-right {
            justify-content: flex-end;
        }

        @media (max-width: 919px) {
            &--content-left,
            &--content-center,
            &--content-right {
                justify-content: center;

                text-align: center;
            }
        }
    }

    &-picture {
        display: block;
        width: 100%;
        height: 100%;

        img,
        source {
            width: 100%;
            height: 100%;
            margin: 0;
            object-fit: contain;
            object-position: left bottom;
        }

        img {
            display: block;
        }
    }

    picture ~ .cta-text,
    img ~ .cta-text {
        & .cta-text__container {
            margin-top: -4%;
            padding: 8px 24px;
        }

        & .cta__call {
            margin-top: 0;
            margin-bottom: 12px;

            color: @white;
            font-weight: bold;
            text-shadow: 1px 1px 1px #000;
        }

        & .cta__action {
            margin-bottom: 0;
            border: 1px solid rgba(255, 255, 255, 0.5);
        }
    }
}
