
.teaser {
    display: block;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
    background: @white;
    transition: box-shadow .15s;
    margin-bottom: 10px;
    position: relative;

    @media @screen-sm-min {
        min-height: 300px;
        height: ~'calc(100% - 20px)'; // Substract margin-bottom to produce margin when child of class "row"
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
    }

    &:hover,
    &:focus {
        text-decoration: none;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.5);
    }

    .no-gutters &:not(:last-child) {
        margin-bottom: 0; //unsereOebb Landing-Page 2x5-Raster
    }
}

.teaser__image-wrapper {
  flex-shrink: 0;
}

.teaser__image {
    width: 100%;
    height: auto;
    display: block;
}

.teaser__desc {
    position: relative;
    padding: 10px 20px 30px;
    text-align: center;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1 0 auto;
}

.teaser__headline {
    margin-bottom: 5px;
    font-size: 26px;
    line-height: 30px;
    font-family: @font-bold;
    color: @black-100;
}

.teaser__intro {
    color: @black-80;
    font-family: @font-medium;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 30px;
}

.teaser__pseudolink {
    background: none;
    border: none;
    cursor: pointer;

    color: @oebb-red;
    font-family: @font-bold;

    &:hover {
        text-decoration: underline;
    }
}

.teaser__series {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    background: @oebb-red;
    color: @white;
    padding: 0 5px;
    text-transform: uppercase;
    font-family: @font-medium;
    font-size: 16px;
    line-break: 28px;
    letter-spacing: 2px;
    transform: translateY(-75%);
    border-top: 2px solid @white;
    border-right: 2px solid @white;
}

// standard
.teaser--standard {
    .teaser__pseudolink {
        position: absolute;
        bottom: 15px;
        left: 50%;
        transform: translateX(-50%);
    }
}

.teaser--hero {
    overflow: hidden;
    .blog .main > .container > &:first-child .teaser__image-wrapper {
        overflow: hidden;
    }


    @media @screen-md-min {
        display: flex;
        flex-direction: row;
    }

    .teaser__image-wrapper {
        position: relative;

        @media @screen-md-min {
            width: ~'calc(100% / 12 * 8 - 10px)';
        }
    }

    .teaser__desc {
        justify-content: center;

        @media @screen-md-min {
            padding: 50px 30px 30px;
            width: ~'calc(100% / 12 * 4 - 10px)';
        }
    }

    .teaser__headline {
        font-size: 26px;
        line-height: 30px;

        @media @screen-md-min {
            font-size: 36px;
            line-height: 38px;
        }
    }

    .teaser__intro {
        margin-bottom: 10px;
    }

    @media @screen-md-min {
        .teaser__series {
            left: auto;
            right: 0;
            transform: none;
        }
    }
}

.teaser--red {
    @media @screen-md-min {
        display: flex;

        > div {
            width: 50%;
        }
    }

    .teaser__desc {
        background: @oebb-red;
        color: @white;
    }

    .teaser__category,
    .teaser__headline,
    .teaser__intro {
        color: @white;
    }

    .teaser__headline {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

.teaser--langer {
    @media @screen-md-min {
        display: flex;
    }

    .teaser__category {
        text-align: left;
    }

    .teaser__desc {
        text-align: left;

        @media @screen-md-min {
            width: ~'calc(100% / 12 * 8)';
        }
    }

    .teaser__image-wrapper {
        @media @screen-md-min {
            width: ~'calc(100% / 12 * 4)';
        }
    }

    .teaser__intro {
        margin-bottom: 15px;
    }
}

.teaser--overlay {
    @media @screen-sm-max {
        height: 420px;
    }

    &:not(.teaser--video) {
        height: 0;
        padding-top: 116.667%;
    }
    

    @media @screen-md-min {
        .col-md-5th & {
            height: 240px;
        }
    }

    .teaser__image {
        position: absolute;
        left: 50%;

        width: auto;
        max-width: none;
        height: 100%;

        transform: translateX(-50%);

        &-wrapper {
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;
            overflow: hidden;

        }
    }

    @fade-text: transform 0.35s ease;
    @fade-opacity: opacity 0.35s ease;

    .teaser__desc {
        text-align: center;
        color: @white;
        position: absolute;
        padding: 25px 20px;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        transition: @fade-text;

        &::after {
            content: '\e315';
            opacity: 0;
            transition: @fade-opacity;

            font-family: 'obb-icon' !important;
            font-size: 24px;
        }
    }

    .teaser__category {
        text-align: center;
        color: @white;
    }

    .teaser__pseudolink,
    .teaser__intro {
        display: none;
    }

    .teaser__headline {
        color: @white;
    }

    &::before,
    &::after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    &::before {
        z-index: 1;
        background: linear-gradient(180deg,rgba(0,0,0,0) 0,rgba(0,0,0,.8) 80%);
        opacity: 1;
        transition: @fade-opacity;
    }

    &::after {
        z-index: 0;
        background: @gradient-red;
        opacity: 0;
        transition: @fade-opacity;
    }

    &:hover {
        &::before {
            opacity: 0;
            transition: @fade-opacity;
        }

        &::after {
            opacity: 1;
            transition: @fade-opacity;
        }

        .teaser__desc {
            transform: translateY(-10px);
            transition: @fade-text;

            &::after {
                opacity: 1;
                transition: @fade-opacity;
            }
        }
    }

    .teaser__series {
        top: -15px;
        right: auto;
        left: 50%;

        border: none;
        transform: translateX(-50%);
    }
}

.teaser--overlay.teaser--video {
    .teaser__headline {
        margin: 0 50px 0 60px;

        text-align: left;
    }

    .teaser__desc {
        bottom: 20px;

        &::before {
            position: absolute;
            top: 50%;
            left: 30px;

            content: '\e038';
            display: block;
            width: 25px;
            height: 30px;

            box-shadow: inset 0 0 0 15px @white;
            transform: translateY(-50%);

            color: @oebb-red;
            font-family: 'obb-icon' !important;
            font-size: 60px;
            line-height: 25px;
            text-indent: -20px;
        }

        &::after {
            position: absolute;
            top: 50%;
            right: 20px;

            transform: translateY(-50%);
        }
    }

    &:hover .teaser__desc {
        transform: none;
        transition: none;
    }
}
