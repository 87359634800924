.scroll-top {
    border-radius: 50%;
    z-index: 5;
    background: white;
    box-shadow: 0 0px 20px rgba(0,0,0,0.28);
    text-align: center;

    position: fixed;
    bottom: 15px;
    right: 15px;

    width: 40px;
    height: 40px;
    font-size: 35px;

    @media @screen-sm-min {
        width: 60px;
        height: 60px;
        font-size: 55px;
    }

    @media @screen-lg-min {
        bottom: 20px;
        right: 20px;
        width: 70px;
        height: 70px;
        font-size: 65px;
    }

    &:hover,
    &:focus {
        background: @oebb-red;
        color: white;
    }

    .obb-icon-arrow-up {
        font-size: 1em;
        width: 100%;
        height: 100%;
    }
}