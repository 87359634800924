
.video-container__outer {
    position: relative;
    width: 100%;
}

.video-container {
    width: 100%;
    height: 0;
    padding-top: 56.25%; //16:9
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
}

.video-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.video-disclaimer {
    padding: 20px 20px 0;
    border: 1px solid @black-100;
    margin-bottom: 20px;
}

.video__disclaimer-txt {
    margin-bottom: 10px;
}

.video__disclaimer-btn {
    margin-bottom: 20px;
}