/** Slider */

.carousel {
    position: relative;
    z-index: 2;

    width: 100%;
    max-width: 1900px;
    margin: 0 auto 20px;
}

.carousel.hide {
    animation: hideTeaser 0.5s forwards;
}

.carousel__view {
    margin-bottom: 0.5em;
    overflow: hidden;
}

.carousel__view-wrapper,
.carousel__view,
.carousel__controls {
    position: relative;
}

.carousel__list {
    width: 100%;
    background-color: @black-20;

    .carousel--unsere-oebb & {
        min-height: ~'calc(100vh - 110px)';

        @media @screen-md-min {
            min-height: auto;
            aspect-ratio: 3.8 / 1;
        }
    }
}

.carousel__item img {
    display: block;
    margin: 0 auto;

    width: 100%;
}

.carousel__item,
.carousel__list > div {
    left: auto;

    display: none;
    width: 100%;
}

.carousel__item--active,
.carousel__list > div.carousel__item--active {
    position: relative;
    top: 0;

    display: block;
    width: 100%;

    transform: translateX(0);

    .carousel--unsere-oebb & {
        position: absolute;
    }
}

/** Animations */

.carousel__item--slide-in-left,
.carousel__list > div.carousel__item--slide-in-left {
    transform: translateX(-100%);
}

.carousel__item--slide-in-right,
.carousel__list > div.carousel__item--slide-in-right {
    transform: translateX(100%);
}

.carousel__item--slide-out-left,
.carousel__list > div.carousel__item--slide-out-left {
     transform: translateX(-100%);
}

.carousel__item--slide-out-right,
.carousel__list > div.carousel__item--slide-out-right {
    transform: translateX(100%);
}

.carousel__item.carousel__item--is-sliding,
.carousel__list > div.carousel__item--is-sliding {
    position: absolute;
    top: 0;
}

/** Forward/Backward Controls */

.carousel__control-forward,
.carousel__control-backward {
    position: absolute;
    top: 50%;

    display: none;
    width: 35px;
    height: 35px;

    cursor: pointer;
    transform: translateY(-50%);
}

.carousel__control-forward {
    right: 0;
}

.carousel__control-backward {
    left: 0;
}

.carousel__control-forward [class*='obb-icon-'],
.carousel__control-backward [class*='obb-icon-'] {
    position: absolute;
    top: 50%;
    left: 50%;

    vertical-align: middle;

    background-color: rgba(0, 0, 0, 0.65);
    border-radius: 50%;
    transform: translate(-50%, -50%);

    color: @white;
    font-size: 28px;
    text-align: center;
    line-height: 1.15em;
}

@media @screen-sm-min {
    .carousel__control-backward,
    .carousel__control-forward {
        display: block;
        width: 65px;
        height: 100%;

        & [class*='obb-icon-'] {
            font-size: 48px;
        }
    }
}

@media @screen-lg-min {
    .carousel__control-forward {
        transform: translate(100%, -50%) translateX(10px);
    }

    .carousel__control-backward {
        transform: translate(-100%, -50%) translateX(-10px);
    }
}

/** Indicators */

.carousel__indicators {
    z-index: 1;

    display: block;
    text-align: center;
}

.carousel__indicator {
    display: inline-block;
    padding: 8px 0;

    cursor: pointer;
}

.carousel__indicator::after {
    content: '';
    display: block;
    width: 40px;
    height: 5px;

    background-color: @black-20;

    @media @screen-xs-min {
        width: 50px;
    }

    .carousel--unsere-oebb & {
        width: 14px;
        height: 24px;

        background-color: @white;
        transform: skew(-42.5deg);
        opacity: .5;
    }
}

.carousel__indicator:focus::after,
.carousel__indicator:hover::after,
.carousel__indicator:active::after,
.carousel__indicator--active::after {
    background-color: @oebb-red;

    .carousel--unsere-oebb & {
        background-color: @oebb-red;
        opacity: 1;
    }
}

/** Controls */

.carousel__controls {
    display: flex;
    justify-content: center;

    .carousel--unsere-oebb & {
        position: absolute;
        bottom: 0;
        left: 50%;

        width: 100%;

        transform: translateX(-50%);
    }
}

.carousel__control-play {
    display: block;
    margin: 0 16px;

    cursor: pointer;

    text-align: center;
    text-decoration: none;

    .carousel--unsere-oebb & {
        position: absolute;
        left: 0;
    }
}

.carousel__control-play:link,
.carousel__control-play:visited,
.carousel__control-play:focus,
.carousel__control-play:hover,
.carousel__control-play:active {
    text-decoration: none;
}

.carousel .carousel__icon-play {
    display: inline-block;

    font-size: 30px;
}

.carousel .carousel__icon-pause {
    display: none;

    font-size: 30px;
}

.carousel--sliding .carousel__icon-pause {
    display: inline-block;
}

.carousel--sliding .carousel__icon-play {
    display: none;
}

/** Property z-index should be higher than in Hero-Teaser elements, because Hero-Teaser can be used as items */

.carousel__control-forward,
.carousel__control-backward,
.carousel__indicators {
    z-index: 10;
}

/** Magnolia edit mode */

.edit-mode .carousel__control-forward,
.edit-mode .carousel__control-backward {
    max-height: 50%;
}

.edit-mode .carousel__item,
.edit-mode .carousel__list > div {
    display: block;
}

.edit-mode .carousel--unsere-oebb .carousel__item--active {
    position: relative !important; // Otherwise active element is not visible in edit mode for "unsere-oebb" carousel
}
