
.breadcrumbs {
    display: none;

    @media @screen-sm-min {
        display: flex;
        list-style: none;
        font-family: @font-medium;
        font-size: 14px;
        line-height: 22px;
        padding-top: 20px;
        padding-bottom: 35px;
        z-index: 1;
        position: relative;

        > li:not(:last-child) {
            white-space: nowrap;
        }
    }
}

.breadcrumb {
    @media @screen-sm-min {
        margin-right: 5px;

        &--active {
            color: @black-80;
        }
    }
}

.breadcrumb__seperator {
    @media @screen-sm-min {
        margin-right: 7px;
        color: @black-80;
    }
}

.landingpage .breadcrumb,
.landingpage .breadcrumb__seperator {
    color: white;
    font-family: @font-bold;

    &--active {
        font-family: @font-regular;
    }
}