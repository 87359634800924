.teaser-slider {
    position: relative;
    padding: 0;

    @media @screen-md-min {
        margin: 0 70px;
    }

    @media @screen-lg-min {
        margin: 0;
    }
}

.teaser-slider__view {
    overflow: hidden;
}

.teaser-slider__items-wrapper {
    flex-wrap: nowrap;
    transition: transform .75s ease;
}

.teaser-slider__control-forward {
    &:extend(.carousel__control-forward all);

    @media @screen-sm-min {
        transform: translate(100%, -50%) translateX(10px);
    }

}

.teaser-slider__control-backward {
    &:extend(.carousel__control-backward all);

    @media @screen-sm-min {
        transform: translate(-100%, -50%) translateX(-10px);
    }

}