.landingpage__hero {
    overflow: hidden;
    height: 0;
    padding-top: ~'calc(580 / 1920 * 100%)';
    position: relative;
    margin-bottom: 50px;

    @media @screen-sm-min {
        margin-top: -80px;
        z-index: 0;
    }

    img {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    &:before {
        content: '';
        display: block;

        width: 100%;
        height: 100%;

        position: absolute;
        top: unset;
        right: 0;

        @media @screen-sm-min {
            height: 140%;
            left: 0;
            bottom: 0;
            background-color: @oebb-red;
            transform-origin: bottom left;
            transform: rotate(42.5deg) scaleX(-1);
            z-index: 2;
        }

    }

    &:after {
        content: '';
        display: block;

        width: 100%;
        height: 100%;

        position: absolute;
        top: 0;
        right: 0;

        @media @screen-sm-min {
            height: 140%;
            left: 0;
            bottom: 0;
            background-color: @oebb-red;
            transform-origin: top right;
            transform: rotate(42.5deg) scaleX(-1);
        }

    }

    .landingpage__hero-text {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;

        display: flex;
        align-items: flex-end;
        padding: 15px;

        @media @screen-sm-min {
            width: 500px;
            align-items: center;
        }
    }

    .landingpage__title {
        color: white;
        z-index: 1;
    }

    
}