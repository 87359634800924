.lang-nav {
    list-style: none;
    position: absolute;
    bottom: 14px;

    @media @screen-sm-max {
        top: 12px;
        bottom: auto;
        left: 12px;
        display: flex;
        transform: translateX(-110%);
        transition: transform .35s ease;
        align-items: center;

        .ocopen & {
            transform: translateX(0%);

            &--slide-out {transform: translateX(-110%);}
        }

        &__item {
            margin-right: 10px;
            padding: 5px;
            line-height: 1em;

            &--active {
                background: @white;

                .lang-nav__link {
                    color: @black-100;
                }
            }
        }
    }

    @media @screen-md-min {
        position: absolute;
        right: 30px;
        background: @white;
        padding: 0;
        display: flex;
        bottom: 16px;

        .header--nav-inline & {
            bottom: 10px;
        }

        &--no-right {
            right: 0;
        }
    }

    @media @screen-lg-min {
        right: 40px;

        &--no-right {
            right: 0;
        }
    }
}

@media @screen-md-min {
    .lang-nav__item {
        margin-left: 5px;
        padding: 0 5px;

        &--active {
            background: @black-80;

            .lang-nav__link {
                color: @white;
            }
        }
    }
}

.lang-nav__link {
    color: @white;
    font-size: 18px;
    line-height: 1em;
    font-family: @font-light;

    @media @screen-md-min {
        color: @black-90;
        font-size: 16px;

        &:hover,
        &:focus {
            text-decoration: none;
            color: @oebb-red;
        }
    }

    @media @screen-lg-min {
        font-size: 18px;
    }
}

.lang-nav .lang-nav__icon {
    display: none;

    @media @screen-md-min {
        display: inline-block;
    }
}