.filter {
    position: relative;
    min-height: 50px;
    z-index: 4;

    background: @white;

    text-align: center;

    &__toggle {
        height: 50px;

        background: transparent;
        border: none;
        cursor: pointer;

        color: @oebb-red;
        font-family: @font-bold;
    
        [class="obb-icon-filter"] {
            font-size: 28px;
        }
    }

    &__no-result {
        margin: 3em auto 2em;

        text-align: center;
    }

    &--open {
        height: auto;

        transition: height 0.35s ease;

        .obb-icon-arrow-down {
            transform: rotate(180deg);
        }
    }

}


.filter-options {
    position: absolute;
    right: 0;
    left: 0;

    height: 0;
    overflow: hidden;

    background: @white;
    transition: height 0.35s ease;

    .filter--open & {
        .dialogShadow();
    }

    &__panel {
        padding-top: 5px;
        padding-bottom: 35px;

        opacity: 0;
        transition: opacity 0.25s ease;

        text-align: left;

        .filter--open & {
            opacity: 1;
            transition: opacity 0.25s ease;
        }
    }
}

.filter-section {
    margin-bottom: 15px;

    @media @screen-md-min {
        &:last-of-type {
            margin-bottom: 30px;
        }
    }

    &__title {
        margin-bottom: 5px;

        font-family: @font-bold;
    }
}

.filter-parameter-list {
    overflow: hidden;

    list-style: none;

    &--overview {
        vertical-align: middle;

        display: inline-block;
    }

    &__item {
        float: left;

        display: block;
        margin-right: 10px;
        margin-bottom: 5px;
    }
}

.filter-parameter {
    display: inline-block;
    padding: 2px 5px;

    background: none;
    border: 1px solid @black-60;
    cursor: pointer;

    color: @black-90;
    font-family: inherit;
    font-size: 14px;
    line-height: 28px;

    @media @screen-md-min {
        padding: 5px 10px;

        font-size: 16px;
    }

    &:focus,
    &:hover,
    &:active {
        text-decoration: none;
    }


    &--active {
        background-color: @oebb-red;
        border-color: @oebb-red;

        color: @white;
        text-decoration: none;
        
        // :hover {
        //     background-color: @dark-red;
        // }
    }

    &--removeable {
        position: relative;

        padding-right: 35px;
    }
    
    &__remove {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;

        padding: 4px 12px;

        background-color: @oebb-red;
        border: none;
        cursor: pointer;

        color: @white;
        font-size: 20px;

        @media @screen-md-min {
            padding: 8px 12px;
        }
    }
}

.filter__button {
    display: block;
    margin: 0 auto;

    font-size: 16px;

    @media @screen-md-min {
        font-size: 18px;
    }
}

.filter-overview {
    margin-top: 16px;

    @media @screen-sm-min {
        margin-top: 0;
    }
}

.filter-reset {
    padding: 8px 0;

    background: transparent;
    border: none;
    cursor: pointer;

    color: @oebb-red;
    font-family: @font-bold;

    [class="obb-icon-repeat"] {
        transform: rotateY(180deg);

        font-size: 16px;
    }
}
