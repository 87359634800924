.article {
    display: block;

    &--category {
        margin-bottom: 30px;
    }
}

.article__intro {
    margin-bottom: 10px;

    @media @screen-sm-min {
        margin-bottom: 20px;
    }
}

.article__content {
    background: @white;
    padding: 25px @article-padding 30px;
    margin-left: -15px;
    margin-right: -15px;

    @media @screen-xs-min {
        margin-left: auto;
        margin-right: auto;
        max-width: ~'calc(100% / 12 * 10)';

        &--intro {
            margin-top: -30px;
            position: relative;
            z-index: 1;
        }

        &--intro-no-hero {
            margin-top: 0;
        }
    }

    @media @screen-md-min {
        padding: 55px @article-md-padding 50px;

        &--intro {
            margin-top: -80px;
        }

        &--intro-no-hero {
            margin-top: 0;
        }
    }

    &.bg--transparent {
        background: transparent;
    }

    p {
        margin-bottom: 15px;

        + h2,
        + h3 {
            margin-top: 15px;
        }

        @media @screen-md-min {
            margin-bottom: 30px;

            + h2,
            + h3 {
                margin-top: 50px;
            }
        }

        .article--category & {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    ul {
        padding: 0 0 0 1em;
        margin-bottom: 30px;
    }
}



.article__hero {
    width: 100%;
    height: 0;
    padding-top: 39.4737%;
    position: relative;
    overflow: hidden;

    img {
        position: absolute;
        top: 0;
        left: 0;
    }
}

.article__category {
    margin-bottom: 10px;

    @media @screen-sm-min {
        margin-bottom: 20px;
    }
}

.article__title {
    text-align: center;
    margin-bottom: 10px;
}

.article__release-date {
    text-align: center;
    margin-bottom: 20px;
}