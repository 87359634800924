
.sharelinks {
    padding: 50px 0 70px;
    border-top: 1px solid @black-20;
    
    @media @screen-md-min {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.sharelinks__likes-counter {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;

    .button {
        margin: 0 0 10px;
    }

   .obb-icon-heart-square {
       font-size: 1em;
   }

   @media @screen-md-min {
       flex-direction: row;
       margin-bottom: 0;

       .button {
        margin: 0 10px 0 0;
    }
   }
}

.likes-counter__txt {
    transition: opacity .35s ease;
}

.likes-counter__number {
    font-family: @font-bold;
}

.sharelinks__social-media {
    display: flex;
    list-style: none;
    justify-content: center;

    @media @screen-md-min {
        justify-content: flex-end;
    }
}

.social-link {
    margin-left: 10px;
    display: block;
    font-size: 0;
    width: auto;
    height: auto;
    transition: opacity .35s ease;

    &:before {
        font-size: 24px;
    }

    &:hover {
        opacity: .75;
    }

    &--facebook { color: #3B5998; }
    &--twitter { color: #55ACEE; }
    &--x-twitter { color: #000000; }
    &--mail { color: @black-80; }
    &--linkedin { color: #0073B0; }
}