.category {
    margin-bottom: 50px;
}

.category:last-child {
    margin-bottom: 0;
}

.category__title {
    margin-bottom: 15px;

    .obb-icon-arrow-right {
        font-size: 34px;
    }
}
