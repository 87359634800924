
.ticker {
    background: @white;
    box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.5), 0 2px 4px 0 rgba(0,0,0,0.21);
    color: @black-80;
    font-family: @font-medium;
}

.ticker__head {
    background: @sand;
    padding: 4px 20px 4px 10px;
    
    font-size: 16px;
    line-height: 28px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.ticker__author {
    display: flex;
    align-items: center;
}

.ticker__author-image {
    display: block;
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.ticker__content {
    padding: 30px 30px 20px;
    background: @white;
    font-size: 21px;
    line-height: 30px;

    > * {
        display: block;
        margin-bottom: 20px;
    }
}
