
.author {
    font-family: @font-medium;
    font-size: 18px;
    line-height: 26px;
}

.author__content {
    &:extend(.article__content all);
    background: transparent;
    text-align: center;
    position: relative;
    padding-top: 100px;

    p {
        margin-bottom: 20px;
    }
}

.author__img {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto -85px;
}

.author__title {
    font-family: @font-bold;
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 15px;

    @media @screen-md-min {
        font-size: 36px;
        line-height: 38px;
    }
}

.author__subtitle {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 1px;
    margin-bottom: 10px;
    text-transform: uppercase;
    display: block;
    font-family: @font-medium;
}
